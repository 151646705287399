import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import CopyableArea from "Components/CopyableArea";

import { Description, LinkWrapper } from "../style";

const GitCliCommand = ({ command }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <div>
      <Description>
        {formatMessage({ id: "git.clone.description" })}
      </Description>

      <CopyableArea
        id="environment-git-cmd"
        content={command}
        singleLine={true}
      >
        {command}
      </CopyableArea>

      <LinkWrapper>
        <a
          href={formatMessage({
            id: "links.documentation.SSH"
          })}
          rel="noopener noreferrer"
          target="_blank"
        >
          {formatMessage({ id: "git.learnmore" })}
        </a>
      </LinkWrapper>
    </div>
  );
};

GitCliCommand.propTypes = {
  command: PropTypes.string
};
export default GitCliCommand;
