import React, { useState } from "react";
import PropTypes from "prop-types";

import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";
import Editor from "./Editor";
import ModalIDE from "./ModalIDE";
import ExpandIcon from "Components/icons/ExpandIcon";

import * as S from "./styles";

const IdeField = ({
  id,
  label,
  value,
  onChange,
  required = true,
  width = "100%",
  height = 600,
  options = {},
  expandable = false
}) => {
  const [isModalOpen, toggleModal] = useState(false);

  const handleChange = newValue => {
    onChange({
      persist: () => {},
      preventDefault: () => {},
      target: {
        type: "text",
        value: newValue,
        name: id
      }
    });
  };

  return (
    <S.Wrapper className="field ide-field">
      <S.Header>
        {label && (
          <Label className="label" htmlFor={id}>
            {label}
            {required ? <RequiredTag id={id} /> : ""}
          </Label>
        )}

        <S.Buttons>
          {expandable && (
            <S.Button onClick={() => toggleModal(true)}>
              <ExpandIcon />
            </S.Button>
          )}
        </S.Buttons>
      </S.Header>

      <Editor
        width={width}
        height={height}
        value={value}
        options={options}
        onChange={handleChange}
      />

      {expandable && isModalOpen && (
        <ModalIDE
          title={label}
          value={value}
          onSave={handleChange}
          closeModal={() => toggleModal(false)}
        />
      )}
    </S.Wrapper>
  );
};

IdeField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  expandable: PropTypes.bool,
  options: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IdeField;
