import styled from "styled-components";

import { css, LINK, getCSSVarString } from "Libs/themes";

const Link = styled.a`
  color:var(--link-skye-color,var(--link-skye,var(--skye)));
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  text-align: left;

  &:focus {
    outline: none;
  }

  &:focus:before {
    content: "";
    position: absolute;
    left: -4px;
    right: -4px;
    top: -2px;
    bottom: -2px;
    border-radius: 4px;
    border: 1px solid var(--link-skye-border-color,var(--link-skye,var(--skye)));
  }

  &:hover:not(:focus):after {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 1px;
    left: 0;
    right: 0;
    display: inline-block;
    background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
  }
`;

export default Link;
