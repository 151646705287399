import styled from "styled-components";
import { getCSSVarString, SECTION } from "Libs/themes";

import { ButtonWrapper } from "ds/Button";

export const Ide = styled.div`
  .CodeMirror {
    border-radius: 2px;
    background-color: ${getCSSVarString(
      SECTION,
      "night",
      "background-color"
    )} !important;
  }

  .CodeMirror-gutters {
    background-color: ${getCSSVarString(
      SECTION,
      "night",
      "background-color"
    )} !important;
  }

  .CodeMirror-gutter {
    width: 68px;
  }

  .CodeMirror-lines {
    padding-left: 0;
  }

  .CodeMirror-linenumber {
    width: 51px !important;
  }
`;

export const Footer = styled(ButtonWrapper)`
  margin-top: 21px;
`;
