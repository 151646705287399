import { useHistory } from "react-router-dom";
import { useMemo, useCallback } from "react";

import useQuery from "Hooks/useQuery";

import { arrayToObject } from "Libs/utils";

const isNullOrUndefined = value => value == null;

const buildURL = (path, queryString) => {
  const filteredQueryString = arrayToObject(
    Object.entries(queryString).filter(([, value]) => !isNullOrUndefined(value))
  );
  const params = new URLSearchParams(filteredQueryString).toString();
  return `${path}?${params}`;
};

const identity = value => value;

/**
 *
 * @param {(value: string) => any} [transform=identity] function to be applied
 * @returns
 */
const transformArray =
  (transform = identity) =>
  elements =>
    elements
      .split(",")
      .filter(element => !!element)
      .map(transform);

export const transforms = {
  /**
   * trasnforms a query string parameter into an array of strings.
   */
  array: transformArray(),
  /**
   * Transforms a query string parameter into an array using a custom transform
   * function for each element.
   */
  arrayOf: transformArray,
  /**
   * Alias to Number.parseInt
   *
   * @see Number.parseInt
   */
  number: number => Number.parseInt(number),
  /**
   *
   * @param {string} element One of the following strings "true", "false"
   * @returns {boolean} the boolean value
   */
  boolean: element => element === "true"
};

/**
 *
 * If the state is holding an object, all keys will be spread on the url like:
 * @example
 * ```js
 * setState({ from: "start", to: "end"});
 * // location.search === "?from=start&to=end"
 * ```
 *
 * @param {string|boolean|array|number|object} initialState State in case the url param is not defined yet
 * @param {string} key Name of the key where the values will be stored
 * @param {(element: string) => any} transform Transforms the value stored
 * on the url to a javascript primitive. It can be any of the exported
 * transform functions or a custom one if needed
 *
 * @returns {[any, setState: (string|boolean|array|number|undefiend) => void]} A tuple containing the current
 * value of the key and a function to update the value. Calling this function
 * will update the url.
 */
const useURLSearchState = (initialState, key, transform = identity) => {
  const urlSearch = useQuery();
  const { push } = useHistory();

  const state = useMemo(() => {
    if (typeof initialState === "object" && !(initialState instanceof Array)) {
      const initialKeys = Object.keys(initialState);

      return {
        ...initialState,
        ...arrayToObject(
          Object.entries(urlSearch)
            .filter(([key]) => initialKeys.includes(key))
            .map(([key, value], index, array) => [
              key,
              transform(value, key, array)
            ])
        )
      };
    }

    // Casting to boolean the property to verify if it exists is not enough
    // If the shape of the query string is `?key=` the value of the property
    // `key` will be an empty string, this could mean an empty array or just
    // an empty value for the parameter therefore it should override the
    // initial state
    return isNullOrUndefined(urlSearch[key])
      ? initialState
      : transform(urlSearch[key]);
  }, [urlSearch, transform, initialState, key]);

  const setState = useCallback(
    value => {
      let queryString = {
        ...urlSearch
      };
      if (typeof value === "object" && !(value instanceof Array)) {
        queryString = {
          ...initialState,
          ...queryString,
          ...value
        };
      } else {
        queryString[key] = value;
      }

      push(buildURL("", queryString));
    },
    [push, urlSearch, key, initialState]
  );

  return [state, setState];
};

export default useURLSearchState;
