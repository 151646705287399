import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  css,
  BUTTON,
  LINK,
  SECTION,
  HEADER,
  getCSSVarString
} from "Libs/themes";

export const Layout = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 24px;
  right: 24px;
`;

export const Popup = styled.div`
  box-sizing: border-box;
  width: 400px;
  padding: 16px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: var(--section-box-shadow-box-shadow,var(--section-box-shadow,var(--box-shadow)))
    var(--section-granite-shadow-box-shadow-color,var(--section-granite-shadow,var(--granite-shadow)));
  border-radius: 2px;
  display: flex;
  gap: 16px;
  flex-direction: row;
`;

export const Icon = styled.div`
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));

  svg {
    width: 24px;
  }
  path {
    fill: currentColor;
  }
`;

export const Minimize = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  color:var(--button-snow-color,var(--button-snow,var(--snow)));
  cursor: pointer;
  transition-duration: 0.25s;
  transition-property: background-color;
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  border-radius: 2px;

  :hover {
    background-color:var(--button-grey-background-color,var(--button-grey,var(--grey)));
  }
`;

export const Content = styled.div`
  a {
    text-decoration: none;
    position: relative;

    :hover:after {
      content: "";
      position: absolute;
      height: 1px;
      bottom: 1px;
      left: 0;
      right: 0;
      display: inline-block;
      background-color:var(--link-skye-light-background-color,var(--link-skye-light,var(--skye-light)));
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Button = styled(Link)`
  border: none;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: var(--section-box-shadow-box-shadow,var(--section-box-shadow,var(--box-shadow)))
    var(--section-granite-shadow-box-shadow-color,var(--section-granite-shadow,var(--granite-shadow)));
  color:var(--section-skye-color,var(--section-skye,var(--skye)));
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 8px;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-decoration: none;

  svg {
    width: 24px;
  }
  path {
    fill: currentColor;
  }
`;
