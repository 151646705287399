import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, getCSSVarString } from "Libs/themes";

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  &.grid {
    display: flex;
    justify-content: center;
    .project-card {
      margin-bottom: 32px;
    }
    @media (min-width: 834px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(362px, 1fr));
      grid-row-gap: 32px;
      grid-column-gap: 32px;
      justify-items: center;
      &.no-results {
        display: flex;
      }
      .project-card {
        margin-bottom: 0;
      }
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, minmax(432px, 1fr));
    }
    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, minmax(366px, 1fr));
    }
    @media (min-width: 1440px) {
      grid-template-columns: repeat(3, minmax(432px, 1fr));
    }
    @media (min-width: 1920px) {
      grid-template-columns: repeat(4, minmax(427px, 1fr));
    }
  }
  &.list {
    display: block;
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    padding: 32px;
    .project-list-item .row,
    .table-header {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      align-items: center;
      box-shadow: none;
    }
    .project-list-item {
      border-bottom: 1px solid
        var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
      box-shadow: none;
      &:last-child {
        border-bottom: none;
      }
    }
    .cell {
      ${semiBoldAlias};
      line-height: 56px;
      font-size: 13px;
      @media (max-width: 1024px) {
        min-width: 20%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .cell-project {
      color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
      width: 100%;
      font-size: 15px;
    }
    .cell-region,
    .cell-activity,
    .cell-status {
      width: 120px;
    }
    .cell-organization,
    .cell-plan {
      text-transform: capitalize;
    }
    .status-notice {
      position: relative;
      display: inline-block;
      top: auto;
      left: auto;
      right: auto;
      border: none;
      height: 20px;
      width: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .stack {
      margin-bottom: 0;
    }
  }
`;
