import styled from "styled-components";
import ActionDropdown from "Components/ActionDropdown";
import { css, ICON, INPUT, BUTTON } from "Libs/themes";

export const ToggleButton = styled.button.attrs({
  className: "toggle-button"
})`
  &.toggle-button {
    margin-right: 8px;
    box-shadow: none;
    &:active {
      box-shadow: none;
    }
  }
  :hover svg [fill] {
    fill:var(--icon-skye-fill,var(--icon-skye,var(--skye)));
  }
`;

export const ProjectsSort = styled(ActionDropdown)`
  &.info-button > button {
    &.open,
    :hover {
      background-color:var(--button-snow-background-color,var(--button-snow,var(--snow)));
    }
    outline: none;
    box-shadow: none;
    height: 32px;
    margin: 1px;
    padding: 0 !important;
    border: none;
    width: auto;
    margin-right: 4px;
    .text {
      padding-left: 8px;
      padding-right: 8px;
      .active {
        display: none;
      }
    }
    span.icon {
      height: 24px;
      width: 24px;
    }
  }

  input[type="radio"]:checked + label {
    color:var(--input-skye-color,var(--input-skye,var(--skye)));
  }

  .radio-field:last-child label,
  .radio-field:last-child {
    margin-bottom: 0;
  }
`;
