import React, { useState } from "react";
import PropTypes from "prop-types";

import { getCSSVarString, ICON } from "Libs/themes";

import InfoIcon from "Icons/InfoIcon";
import WarningIcon from "Icons/WarningIcon";
import SuccessIcon from "Icons/SuccessStateIcon";
import ErrorIcon from "Icons/ErrorIcon";

import * as S from "./styles";

const getIcon = level => {
  switch (level) {
    case "warning":
      return <WarningIcon color={"var(--icon-fire-fill,var(--icon-fire,var(--fire)))"} />;
    case "success":
      return <SuccessIcon color={"var(--icon-java-fill,var(--icon-java,var(--java)))"} />;
    case "error":
      return <ErrorIcon color={"var(--icon-red-fill,var(--icon-red,var(--red)))"} />;
    default:
      return <InfoIcon color={"var(--icon-skye-fill,var(--icon-skye,var(--skye)))"} />;
  }
};

const Flag = ({ level, singleLine, onClose, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(true);

  const icon = getIcon(level);

  return (
    isOpen && (
      <S.Layout singleLine={singleLine} {...props}>
        <S.IconContainer>{icon}</S.IconContainer>
        <S.Content>{children}</S.Content>
        <S.Close
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
        />
      </S.Layout>
    )
  );
};

Flag.defaultProps = {
  level: "info",
  inline: false,
  onClose: () => undefined
};

Flag.propTypes = {
  level: PropTypes.string,
  singleLine: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

export const Title = S.Title;
export const Description = S.Description;

export default Flag;
