import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";
import { getCSSVarString, ICON } from "Libs/themes";

import AccessibleTooltip from "Components/AccessibleTooltip";
import InProgressStateIcon from "Icons/InProgressStateIcon";
import PendingStateIcon from "Icons/PendingStateIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import CancelledStateIcon from "Icons/CancelledStateIcon";
import WarningIcon from "Icons/WarningIcon";

import * as S from "./styles";

const StatusIcon = ({ state, result, viewLogs }) => {
  let icon = null;

  if (state === "in_progress") {
    icon = <InProgressStateIcon />;
  }

  if (state === "pending") {
    icon = <PendingStateIcon color={"var(--icon-gold-fill,var(--icon-gold,var(--gold)))"} />;
  }

  if (state === "complete" && result === "success") {
    icon = <SuccessStateIcon color={"var(--icon-java-fill,var(--icon-java,var(--java)))"} />;
  }

  if (state === "complete" && result === "cancelled") {
    icon = (
      <CancelledStateIcon color={"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"} />
    );
  }

  if (state === "complete" && result === "failure") {
    icon = <WarningIcon color={"var(--icon-fire-fill,var(--icon-fire,var(--fire)))"} />;
  }
  return (
    <AccessibleTooltip
      as="span"
      tooltipProps={{
        id: `activity-status-${useUniqueId()}`,
        children: state === "complete" ? result : state.replace("_", " ")
      }}
    >
      <S.Button onClick={viewLogs}>{icon}</S.Button>
    </AccessibleTooltip>
  );
};

StatusIcon.propTypes = {
  state: PropTypes.oneOf(["complete", "pending", "in_progress"]),
  result: PropTypes.oneOf(["success", "failure", "cancelled"]),
  viewLogs: PropTypes.func
};

export default StatusIcon;
