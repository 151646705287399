import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import * as S from "./style";

const Tab = ({ tabItems, tabName }) => {
  const [currentTab, setCurrentTab] = useState();

  useEffect(() => {
    setCurrentTab(tabItems?.find(item => item?.isDefaultOpen) || tabItems?.[0]);
  }, [tabItems]);

  const { component: TabComponent, props } = currentTab || {};

  return (
    <S.TabContainer className="tab">
      <S.TabList aria-label={`${tabName} tabs`}>
        {tabItems?.map((item, i) => {
          const isActive = currentTab === item;
          return (
            <S.Tab
              key={`${item.title}_${i}`}
              id={item.id ? item.id : `${tabName}-tab-${i}`}
              isActive={isActive}
              onClick={() => setCurrentTab(item)}
              tabIndex="0"
              aria-selected={isActive ? "true" : "false"}
              role="tab"
              aria-label={`${item.title} tab`}
            >
              {item.title}
              <S.TabUnderLine isActive={isActive} />
            </S.Tab>
          );
        })}
      </S.TabList>
      <S.TabDivider />
      <S.TabPanel tabIndex="0">
        {TabComponent && <TabComponent {...props} />}
      </S.TabPanel>
    </S.TabContainer>
  );
};

Tab.propTypes = {
  tabItems: PropTypes.array,
  tabName: PropTypes.string
};

export default Tab;
