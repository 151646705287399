import React from "react";
import PropTypes from "prop-types";
import Illustration from "./Illustration";

const EmptyMetricsSVG = ({
  uid,
  maincolor,
  bgcolor1,
  // eslint-disable-next-line no-unused-vars
  highlightClass,
  ...otherProps
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 103 107"
    role="img"
    aria-labelledby={`empty-metrics-illustration-${uid}`}
    {...otherProps}
  >
    <title id={`empty-metrics-illustration-${uid}`}>Empty metrics</title>
    <path
      fill={bgcolor1}
      d="M73.9,73.1c0.5,8.3-2.3,16.4-7.8,22.6c-5.5,6.2-13.2,10-21.4,10.6c-8.3,0.6-16.4-2.1-22.7-7.5
	c-6.3-5.4-10.2-13-10.9-21.3c-0.1-1.8-0.1-3.5,0-5.3c0.2-2.8-0.2-5.7-1.3-8.3c-1-2.6-2.7-5-4.7-7c-1.7-1.6-3-3.5-3.9-5.7
	C0.4,49.3-0.1,47,0,44.7c0.1-2.3,0.6-4.6,1.6-6.7c1-2.1,2.4-3.9,4.2-5.4c1.8-1.5,3.8-2.6,6-3.3c2.2-0.7,4.5-0.8,6.8-0.5
	c2.3,0.3,4.5,1.1,6.5,2.3c2,1.2,3.7,2.8,5,4.7c1.6,2.3,3.7,4.2,6.2,5.6c2.5,1.4,5.2,2.2,8,2.3h0.3c7.6,0.5,14.8,3.8,20.2,9.2
	C70.1,58.3,73.4,65.5,73.9,73.1L73.9,73.1z"
    />
    <path fill={bgcolor1} d="M99.5,36l-25-3l-33-29l38-3l19,7L99.5,36z" />
    <g>
      <path
        fill={maincolor}
        d="M63.6,12.3c-6.1-6-15.2-7.6-23-4.1c-7.7,3.5-12.5,11.9-11.7,20.3c0.8,8.5,7,15.8,15.3,17.9c8.3,2.1,17.1-1.4,21.9-8.4
		C71.5,30,70.3,19.1,63.6,12.3z M65,34.1c-3.2,6.6-10.1,10.6-17.4,9.9c-7.2-0.7-13.4-6.1-15.2-13.1c-1.7-7,1.2-14.6,7.2-18.6
		c6.9-4.6,16.1-3.7,21.9,2.1C66.5,19.5,68.1,27.5,65,34.1z"
      />
      <path
        fill={maincolor}
        d="M61.2,20.3c-0.8,0.1-1.4,1-1.1,1.8c1.8,4.5,0.9,9.4-2.5,12.9c-1.4,1.4,0.8,3.5,2.1,2.1c4.1-4.1,5.4-10.4,3.3-15.8
		C62.7,20.5,62,20,61.2,20.3z"
      />
      <path
        fill={maincolor}
        d="M39.9,15.8c-1.9,0-1.9,3,0,3C41.8,18.8,41.8,15.8,39.9,15.8z"
      />
      <path
        fill={maincolor}
        d="M49.2,34.3c-1.7,0-3,1.4-3,3c0,1.6,1.4,2.9,3,2.9c1.6,0,2.9-1.3,2.9-2.9C52.1,35.6,50.9,34.3,49.2,34.3z"
      />
      <path
        fill={maincolor}
        d="M50,15.6c-5.2,0-7.1,3.5-7.2,6.8h4.6c0.2-1.6,1.2-2.5,2.7-2.5c1.3,0,2.3,0.8,2.3,2.1c0,1.2-0.5,1.9-2.6,3.4
		c-2.7,1.8-3.1,4-3.2,6v1.3h4.5v-1c0-2.1,0.8-3,2.5-4.3c2.2-1.7,3.4-3,3.4-5.7C57,17.8,54.4,15.6,50,15.6z"
      />
      <path
        fill={maincolor}
        d="M101.3,71.3c-0.4-0.6-0.9-1.1-1.4-1.6c-5-5-10-10-15-15c-4.5-4.5-8.9-9-13.3-13.3c0,0,0,0,0-0.1
		c6.9-10.5,5.3-24.7-3.4-33.6c-0.2-0.2-0.4-0.3-0.6-0.3c-8-7.4-19.7-9.5-29.7-4.9C27.8,7.1,21.5,18.1,22.7,29.2
		c1.1,11.2,9.3,20.7,20.2,23.4c7.5,1.8,15.3,0.2,21.5-4c3.7,3.7,7.2,7.2,10.9,10.9c5.5,5.5,10.9,10.9,16.4,16.4c2,2,4,4.1,7.2,2.9
		C101.8,77.7,103.1,73.9,101.3,71.3z M47,50.3c-10-0.9-18.5-8.4-20.8-18.1c-2.3-9.6,1.7-19.9,9.9-25.3c9.4-6.2,22-4.9,29.9,3
		c6.9,7,9,17.9,4.7,26.9S56.9,51.2,47,50.3z M66.8,46.7c1.1-0.9,2.1-1.9,3-3c2.9,2.9,5.7,5.7,8.6,8.6c-1,1-2,2-3,3
		C72.5,52.4,69.7,49.5,66.8,46.7z M77.4,57.4c1-1,2-2,3-3c1.3,1.3,2.6,2.6,3.8,3.8c-1,1-2,2-3,3l0,0c-0.8-0.8-1.7-1.7-2.5-2.5
		C78.3,58.3,77.9,57.9,77.4,57.4z M95.5,75.5c-0.5-0.4-0.9-0.9-1.3-1.3c-3.6-3.6-7.2-7.2-10.8-10.8c1-1,2-2,3-3
		c3.2,3.2,6.4,6.4,9.5,9.5c0.9,0.9,3.1,2.5,3.3,4C99.4,76,96.9,76.7,95.5,75.5z"
      />
    </g>
    <path
      fill={maincolor}
      d="M85.4,81.9c0-3.2-2-5.9-5-7c-2.9-1.1-6.3-0.2-8.3,2.2c-0.1,0.1-0.2,0.3-0.3,0.4c-2.8-1.6-5.6-3.1-8.3-4.8
	c-0.1-0.1-0.3-0.1-0.4-0.2c0.1-0.3,0.1-0.6,0.1-1c0.1-0.2,0.1-0.4,0.1-0.6c0-3.2-2-5.9-5-7c-2.9-1.1-6.3-0.2-8.3,2.2
	c-1.4,1.7-2,3.9-1.7,6c-3.2,1.8-6.3,3.5-9.4,5.4c0,0,0,0,0,0c-0.9-1.2-2.1-2.1-3.6-2.6c-1.8-0.7-3.7-0.6-5.4,0.1
	c-4.4-6.1-8.7-12.2-13.2-18.3c-0.5-0.6-0.9-1.2-1.3-1.9c1-1.2,1.7-2.7,1.8-4.3c0.1-0.2,0.1-0.4,0.1-0.6c0-3.2-2-5.9-5-7
	c-2.9-1.1-6.3-0.2-8.3,2.2c-2,2.4-2.4,5.9-0.7,8.7c1.6,2.7,4.8,4.1,7.9,3.5c0.6-0.1,1.3-0.3,1.8-0.6c4.4,6.1,8.7,12.1,13.1,18.2
	c0.5,0.6,0.9,1.2,1.3,1.8c-0.1,0.1-0.3,0.3-0.4,0.4c-2,2.4-2.4,5.9-0.7,8.7c1.6,2.7,4.8,4.1,7.9,3.5c3.3-0.6,5.7-3.5,6-6.8
	c0.1-0.2,0.1-0.4,0.1-0.6c0-0.6-0.1-1.1-0.2-1.7c3.1-1.8,6.1-3.5,9.2-5.2c0,0,0.1,0,0.1-0.1c1.6,2.6,4.8,3.9,7.8,3.4
	c1.9-0.3,3.5-1.5,4.6-3c2.7,1.5,5.5,3.1,8.2,4.7c0.2,0.1,0.3,0.2,0.4,0.3c-0.4,1.8-0.2,3.8,0.9,5.5c1.6,2.7,4.8,4.1,7.9,3.5
	c3.3-0.6,5.7-3.5,6-6.8C85.3,82.3,85.4,82.1,85.4,81.9z M10.7,54.3c0,0-0.4,0.1-0.2,0c-0.1,0-0.3,0-0.4,0c-0.7,0-0.9,0-1.5-0.2
	c-0.6-0.2-0.7-0.2-1.2-0.6c-0.4-0.3-0.7-0.5-1.1-1c0.1,0.1,0,0-0.1-0.1C6.1,52.3,6,52.1,5.9,52c-0.1-0.1-0.1-0.2-0.2-0.3
	c0,0-0.2-0.5-0.1-0.2c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.4-0.1-0.6c0-0.5,0-1,0.1-1.3c0.1-0.7,0.2-0.9,0.6-1.4
	c0.9-1.6,2.9-2.5,4.6-2.1c2.2,0.6,3.7,2.5,3.8,4.6c0,1.8-1.2,3.6-2.9,4.2C11.2,54.2,11,54.3,10.7,54.3z M34.5,86.1
	c-0.3,0.1-0.5,0.2-0.8,0.2c0,0-0.4,0.1-0.2,0c-0.1,0-0.3,0-0.4,0c-0.7,0-0.9,0-1.5-0.2c-0.6-0.2-0.7-0.2-1.2-0.6
	c-0.4-0.3-0.7-0.5-1.1-1c0.1,0.1,0,0-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.2-0.5-0.1-0.2
	c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.4-0.1-0.6c0-0.5,0-1,0.1-1.3c0.1-0.7,0.2-0.9,0.6-1.4c0.2-0.4,0.5-0.8,0.9-1.1
	c0.4-0.1,0.7-0.3,0.9-0.6c0.9-0.4,1.9-0.6,2.8-0.4c1.4,0.4,2.5,1.3,3.2,2.5c0,0.3,0.2,0.5,0.3,0.7c0.2,0.4,0.3,0.9,0.3,1.4
	C37.4,83.7,36.2,85.5,34.5,86.1z M57.5,75.1c-0.3,0.1-0.5,0.2-0.8,0.2c0,0-0.4,0.1-0.2,0c-0.1,0-0.3,0-0.4,0c-0.7,0-0.9,0-1.5-0.2
	c-0.6-0.2-0.7-0.2-1.2-0.6c-0.4-0.3-0.7-0.5-1.1-1c0.1,0.1,0,0-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
	c0,0-0.2-0.5-0.1-0.2c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.4-0.1-0.6c0-0.5,0-1,0.1-1.3c0.1-0.7,0.2-0.9,0.6-1.4
	c0.9-1.6,2.9-2.5,4.6-2.1c2.2,0.6,3.7,2.5,3.8,4.6c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
	C59.3,73.9,58.5,74.7,57.5,75.1z M79.5,86.1c-0.3,0.1-0.5,0.2-0.8,0.2c0,0-0.4,0.1-0.2,0c-0.1,0-0.3,0-0.4,0c-0.7,0-0.9,0-1.5-0.2
	c-0.6-0.2-0.7-0.2-1.2-0.6c-0.4-0.3-0.7-0.5-1.1-1c0.1,0.1,0,0-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
	c0,0-0.2-0.5-0.1-0.2c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.4-0.1-0.6c0-0.5,0-1,0.1-1.3c0-0.1,0-0.3,0.1-0.4
	c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.6,2.9-2.5,4.6-2.1c2.2,0.6,3.7,2.5,3.8,4.6
	C82.4,83.7,81.2,85.5,79.5,86.1z"
    />
  </svg>
);

EmptyMetricsSVG.propTypes = {
  bgcolor1: PropTypes.string,
  maincolor: PropTypes.string,
  highlightClass: PropTypes.string,
  uid: PropTypes.number
};

const EmptyMetrics = props => (
  <Illustration {...props}>
    <EmptyMetricsSVG />
  </Illustration>
);

export default EmptyMetrics;
