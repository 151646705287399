import React from "react";
import PropTypes from "prop-types";

import InfoDialog from "Components/InfoDialog";

import {
  ExtraEnvironmentsList,
  EnvironmentName,
  Layout,
  ExtraNumber,
  TreeDot
} from "./styles";

const EnvironmentList = ({ environmentsName, maxNumber = 5 }) => (
  <Layout>
    {environmentsName.slice(0, maxNumber).join(", ")}
    {environmentsName.length >= maxNumber && (
      <div style={{ display: "flex" }}>
        <TreeDot>...</TreeDot>
        <InfoDialog
          text={
            <ExtraEnvironmentsList>
              {environmentsName.slice(maxNumber).map(n => (
                <EnvironmentName key={n}>{n}</EnvironmentName>
              ))}
            </ExtraEnvironmentsList>
          }
          icon={
            <ExtraNumber>+{environmentsName.length - maxNumber}</ExtraNumber>
          }
          align="center"
        />
      </div>
    )}
  </Layout>
);

EnvironmentList.propTypes = {
  environmentsName: PropTypes.array,
  maxNumber: PropTypes.number
};

export default EnvironmentList;
