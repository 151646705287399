import styled from "styled-components";

import { css, SECTION, ICON } from "Libs/themes";

import { CloseButton } from "ds/Button";

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0 24px 0;
  padding: 12px;
  border-radius: 4px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: ${props =>
    props.theme.name === "dark"
      ? "0px 6px 44px #01001A"
      : "0px 10px 20px rgba(75, 97, 128, 0.08)"};

  svg path {
    fill:var(--icon-fire-fill,var(--icon-fire,var(--fire)));
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px;

    .icon-warning-wrapper {
      display: inline-block;
      margin-right: 12px;
      vertical-align: middle;
    }
  }
`;
export const Close = styled(CloseButton)`
  position: absolute;
  right: 8px;
  top: 50%;
  padding: 0;
  margin: 0;
  transform: translate(0, -50%);
`;
