import styled from "styled-components";
import { css, SECTION, getCSSVarString, ICON, BUTTON } from "Libs/themes";

export const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;

  path {
    fill: ${props =>
      props.hasError
        ?"var(--icon-red-fill,var(--icon-red,var(--red)))":"var(--icon-skye-fill,var(--icon-skye,var(--skye)))"};
  }
`;
export const ErrorMessage = styled.div`
  color:var(--section-red-color,var(--section-red,var(--red)));
  font-size: 13px;
  margin-bottom: 16px;
`;

export const Layout = styled.div``;

export const FileInput = styled.input`
  display: none;
`;

export const CustomFileInput = styled.button.attrs({ type: "button" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  border: 0;
  background-color: transparent;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${props =>
    props.hasError
      ?"var(--icon-red-fill,var(--icon-red,var(--red)))":"var(--button-skye-color,var(--button-skye,var(--skye)))"};

  cursor: pointer;
`;

export const AttachmentList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

export const BundleSize = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  margin-bottom: 16px;
`;
