import styled from "styled-components";

export const Wrapper = styled.div`
  #cli-dropdown-window {
    max-width: 100%;
    @media (min-width: 960px) {
      max-width: 400px;
    }
  }
  #tooltip-content-wrapper {
    padding-top: 11px;
  }
`;

export const Description = styled.p`
  color: ${props => props.theme.sectionHeading};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

export const LinkWrapper = styled.div`
  padding-top: 16px;
  font-weight: 600;
  width: fit-content;
  a {
    color: ${props => props.theme.primary};
    font-size: 14px;
    line-height: 24px;
    display: block;
    text-decoration: none;
  }
`;
