import React from "react";

import { getCSSVarString, ICON } from "Libs/themes";

import * as S from "./ServicesDisplaySkeleton.styles";

const Graph = () => {
  return (
    <S.GraphLayout>
      <svg
        width="189"
        height="151"
        viewBox="0 0 189 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M159.941 94.3433V94.3433C159.941 83.9294 151.497 75.4884 141.083 75.4922L95.0427 75.5087M30.1445 94.2923V94.2923C30.1445 83.9302 38.5427 75.529 48.9048 75.5253L95.0427 75.5087M95.0427 75.5087V55.3432"
          stroke={"var(--icon-stone-background-color,var(--icon-stone,var(--stone)))"}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <rect
          x="66.9409"
          y="28.2843"
          width="40"
          height="40"
          rx="12"
          transform="rotate(-45 66.9409 28.2843)"
          fill={"var(--icon-stone-fill,var(--icon-stone,var(--stone)))"}
        />
        <rect
          x="131.941"
          y="122.627"
          width="40"
          height="40"
          rx="12"
          transform="rotate(-45 131.941 122.627)"
          fill={"var(--icon-stone-fill,var(--icon-stone,var(--stone)))"}
        />
        <rect
          width="40"
          height="40"
          rx="12"
          transform="matrix(0.719666 -0.69432 0.719666 0.69432 0.941162 122.116)"
          fill={"var(--icon-stone-fill,var(--icon-stone,var(--stone)))"}
        />
      </svg>
    </S.GraphLayout>
  );
};

export default Graph;
