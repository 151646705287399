import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import { css, SECTION } from "Libs/themes";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

const InvitationConfirmationModal = ({ isOpen, closeModal, email }) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-medium"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "invitation.confirmationModalTitle" })}
      id={"invitation-confirmation-modal"}
    >
      <ModalBody className="modal-body">
        <FormattedMessage
          id="invitation.confirmationModalBody"
          values={{
            email
          }}
        />
      </ModalBody>
      <ButtonWrapper hasSpacing justifyContent="end">
        <Button onClick={closeModal}>
          <FormattedMessage id="ok" />
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

InvitationConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  closeModal: PropTypes.func
};

export default InvitationConfirmationModal;
