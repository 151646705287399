import React from "react";
import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, getCSSVarString, BUTTON, LINK } from "Libs/themes";

const Button = styled(props => {
  return (
    <button {...props} aria-disabled={!!props.disabled}>
      {props.children}
    </button>
  );
})`
  background-color:var(--button-skye-background-color,var(--button-skye,var(--skye)));
  color:var(--button-snow-color,var(--button-snow,var(--snow)));
  border: 1px solid transparent;
  border-radius: 2px;
  padding: 0 24px 2px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background-color:var(--button-skye-dark-background-color,var(--button-skye-dark,var(--skye-dark)));
    box-shadow: none;
  }
  &:focus {
    border-color:var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
    box-shadow: 0 0 2px
      var(--button-skye-dark-box-shadow-color,var(--button-skye-dark,var(--skye-dark)));
    outline: none;
  }
  &:active {
    box-shadow: none;
    background-color:var(--button-skye-dark-background-color,var(--button-skye-dark,var(--skye-dark)));
  }

  &.secondary {
    background: transparent;
    color:var(--button-slate-color,var(--button-slate,var(--slate)));
    &:hover,
    &:active {
      background-color:var(--link-grey-background-color,var(--link-grey,var(--grey)));
    }
    &:focus {
      border-color:var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
      box-shadow: 0 0 2px
        var(--button-skye-dark-box-shadow-color,var(--button-skye-dark,var(--skye-dark)));
    }
  }

  &.outline {
    background: transparent;
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
    border: 1px solid
      var(--button-periwinkle-grey-border-color,var(--button-periwinkle-grey,var(--periwinkle-grey)));
    margin: ${({ margin }) => margin};
    line-height: 30px;
    &:hover,
    &:active {
      color:var(--button-snow-color,var(--button-snow,var(--snow)));
      background-color:var(--button-skye-dark-background-color,var(--button-skye-dark,var(--skye-dark)));
      border-color:var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
    }
    &:focus {
      border-color:var(--button-skye-dark-border-color,var(--button-skye-dark,var(--skye-dark)));
      box-shadow: 0 0 2px
        var(--button-skye-dark-box-shadow-color,var(--button-skye-dark,var(--skye-dark)));
    }
  }
  &:disabled {
    background-color:var(--button-skye-light-background-color,var(--button-skye-light,var(--skye-light)));
    cursor: not-allowed;
  }
  &.edit {
    width: 80px;
    font-size: 15px;
    padding: 0 0;
    text-align: right;
    cursor: pointer;
    background: transparent;
    border-radius: 0;
    border: none;
    color:var(--button-dark-tint-color,var(--button-dark-tint,var(--dark-tint)));
    ${semiBoldAlias};
    img {
      margin-left: 10px;
    }
  }
  &.delete {
    color:var(--button-red-color,var(--button-red,var(--red)));
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border-radius: 0;
    border: none;
    float: right;
    padding: 0;
  }
  &:first-letter {
    text-transform: uppercase;
  }
  &.link-style {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    background: transparent;
    border: 1px solid transparent;
    height: auto;
    line-height: inherit;
    position: relative;
    font-weight: 400;
    &:focus,
    &:hover {
      &:after {
        content: "";
        width: calc(100% - 16px);
        position: absolute;
        height: 1px;
        background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
        bottom: -1px;
        left: 8px;
        display: inline-block;
      }
    }
    &:focus {
      outline: none;
      border: 1px solid var(--link-skye-dark-border-color,var(--link-skye-dark,var(--skye-dark)));
      border-radius: 4px;
      box-shadow: 0 0 2px box-shadow-color:var(--link-skye-dark-box-shadow-color,var(--link-skye-dark,var(--skye-dark)));
      text-decoration: none;
      margin: -1px -9px;
    }
    &:active {
      border: 1px solid transparent;
      box-shadow: none;
      margin: 0 -8px;
    }
  }
`;

export default Button;
