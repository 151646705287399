import React from "react";

import initials from "initials";

// from https://flatuicolors.com/
const defaultColors = [
  "#2ecc71", // emerald
  "#3498db", // peter river
  "#8e44ad", // wisteria
  "#e67e22", // carrot
  "#e74c3c", // alizarin
  "#1abc9c", // turquoise
  "#2c3e50" // midnight blue
];

const contrast = (hex: string) => {
  const rgb = (
    hex.length === 7
      ? [hex.charAt(1), hex.charAt(3), hex.charAt(5)]
      : [hex.charAt(1), hex.charAt(2), hex.charAt(3)]
  ).map(char => parseInt(char, 16) / 16);

  return rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 <= 0.7
    ? "dark"
    : "light";
};

type UserAvatarProps = {
  borderRadius?: string;
  src?: string;
  srcset?: string;
  name: string;
  color?: string;
  colors?: string[];
  size: number;
  style?: React.CSSProperties;
  className?: string;
};

const UserAvatar = (props: UserAvatarProps) => {
  const {
    borderRadius = "100%",
    src,
    srcset,
    name,
    color,
    colors = defaultColors,
    style,
    className
  } = props;

  if (!name) throw new Error("UserAvatar requires a name");
  if (!props.size) throw new Error("UserAvatar requires a size");

  const abbr = initials(name);
  const size = props.size.toString(10) + "px";

  const innerStyle: React.CSSProperties = {
    lineHeight: size,
    textAlign: "center",
    borderRadius,
    maxWidth: size,
    maxHeight: size,
    width: size,
    height: size,
    backgroundColor: undefined
  };

  const classes = [className, "UserAvatar"];

  if (!src && !srcset) {
    innerStyle.backgroundColor =
      color ||
      colors[
        name
          .split("")
          .map(char => char.charCodeAt(0))
          .reduce((sum, code) => sum + code, 0) % colors.length
      ];

    classes.push(`UserAvatar--${contrast(innerStyle.backgroundColor)}`);
  }

  return (
    <div aria-label={name} className={classes.join(" ")} style={style}>
      <div className="UserAvatar--inner" style={innerStyle}>
        {src || srcset ? (
          <img
            className="UserAvatar--img"
            style={{
              display: "block",
              borderRadius,
              width: size,
              height: size
            }}
            src={src}
            srcSet={srcset}
            alt={name}
          />
        ) : (
          abbr
        )}
      </div>
    </div>
  );
};

export default UserAvatar;
