import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import ActionDropdown from "Components/ActionDropdown";
import Tab from "Components/Tab";

import CLICommand from "./cli/CLI";
import GitCliCommand from "./git/Git";
import * as S from "./style";

const Code = ({ gitCommand, platformCommand }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <S.Wrapper>
      <ActionDropdown
        closeOnContentClick={false}
        id="cli-dropdown"
        label={formatMessage({ id: "code" })}
      >
        <Tab
          tabName="code"
          tabItems={[
            {
              id: "code-tab-cli",
              isDefaultOpen: true,
              title: formatMessage({ id: "cli" })?.toUpperCase(),
              props: { command: platformCommand },
              component: CLICommand
            },
            {
              id: "code-tab-git",
              title: formatMessage({ id: "git" })?.toUpperCase(),
              props: { command: gitCommand },
              component: GitCliCommand
            }
          ]}
        />
      </ActionDropdown>
    </S.Wrapper>
  );
};

Code.propTypes = {
  gitCommand: PropTypes.string,
  platformCommand: PropTypes.string
};

export default Code;
