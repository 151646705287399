import styled from "styled-components";

import { breakpoints } from "Libs/theme";
import { css, HEADER } from "Libs/themes";

const Header = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-top: 0;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints[2]}) {
    font-size: 20px;
  }
`;

export default Header;
