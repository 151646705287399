import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export const Layout = styled.div`
  .checkbox-field,
  .checkbox-field label {
    margin: 0;
  }
  .checkbox-field {
    margin-bottom: 8px;
    &.checked {
      label {
        color:var(--section-skye-color,var(--section-skye,var(--skye)));
      }
    }
  }
`;
