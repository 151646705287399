import styled from "styled-components";

import { breakpoints } from "Libs/theme";
import { css, SECTION, getCSSVarString } from "Libs/themes";

const getColor = props => {
  switch (props.priority) {
    case "low":
      return"var(--section-granite-color,var(--section-granite,var(--granite)))";

    case "normal":
      return"var(--section-slate-color,var(--section-slate,var(--slate)))";

    case "high":
      return"var(--section-fire-color,var(--section-fire,var(--fire)))";

    case "urgent":
      return"var(--section-red-color,var(--section-red,var(--red)))";

    default:
      return"var(--section-granite-color,var(--section-granite,var(--granite)))";
  }
};

const Cell = styled.td`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  color: ${getColor};

  @media (min-width: ${breakpoints[1]}) {
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }
`;

export default Cell;
