import styled from "styled-components";
import { css, INPUT, getCSSVarString } from "Libs/themes";

export const CheckboxInput = styled.input`
  height: 18px;
  width: 18px;
  cursor: pointer;
  /* checked mark aspect */
  &:checked:after {
    content: " ";
    background-color:var(--input-skye-background-color,var(--input-skye,var(--skye)));
    background-size: contain;
    height: 18px;
    width: 18px;
    border-radius: 1px;
  }

  /* disabled checkbox */
  &:disabled:not(:checked),
  &:disabled:checked {
    box-shadow: none;
    border-color:var(--input-periwinkle-grey-border-color,var(--input-periwinkle-grey,var(--periwinkle-grey)));
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* accessibility */
  &:checked:focus,
  &:not(:checked):focus {
    border-color:var(--input-skye-dark-border-color,var(--input-skye-dark,var(--skye-dark)));
    box-shadow: 0 0 2px
      var(--input-skye-dark-box-shadow-color,var(--input-skye-dark,var(--skye-dark)));
  }
  &.changed:checked:focus &.changed:not(:checked):focus {
    box-shadow: none;
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }
`;

export const CheckedCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid var(--input-granite-border-color,var(--input-granite,var(--granite)));
  background: transparent;
  border-radius: 3px;
  margin: 3px 3px 3px 4px;
  cursor: pointer;
  &:hover {
    border-color:var(--input-skye-border-color,var(--input-skye,var(--skye)));
  }
`;

export const CheckedCheckboxTick = styled.div`
  background-color:var(--input-skye-background-color,var(--input-skye,var(--skye)));
  background-size: contain;
  height: 10px;
  width: 10px;
  border-radius: 1px;
`;
