import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Map } from "immutable";

import { loadRegions } from "Reducers/project/region";
import { getOrganizationRegions } from "Reducers/organization/region";
import Dropdown from "Components/Dropdown";

const Layout = styled.div`
  .dropdown {
    display: block;
    margin-bottom: 30px;
    .input-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .Select-menu-outer {
      .Select-option {
        padding-left: 2em;
      }
      .Select-option.is-disabled {
        cursor: default;
        padding-left: 1em;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .description {
    display: flex;
    align-items: center;
    .info-dialog {
      margin: 0 12px 0 0;
    }
  }
`;

class RegionPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onChange = this.onChange.bind(this);
    this.checkPremium = this.checkPremium.bind(this);
  }

  componentDidMount() {
    if (this.props.region) {
      this.setState({
        ...this.props.region
      });
    }

    this.props.loadRegions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organizationId !== this.props.organizationId)
      this.props.loadRegions();
  }

  onChange(region) {
    this.props.onChange(region);
    this.setState({
      region
    });
  }

  checkPremium(region) {
    if (region && region.id && region.id.includes("de")) {
      return true;
    }
    return false;
  }

  getOptions(options) {
    const optionsList =
      typeof options.isArray === "function" && options.isArray()
        ? options
        : options.only;
    return optionsList || [];
  }

  render() {
    const { regions, regionOptions } = this.props;
    if (!this.props.regions) {
      return false;
    }

    const regionList = regions.toJS();
    const restrictedRegions = this.getOptions(regionOptions);

    const options = [];
    Object.keys(regionList).forEach(zone => {
      const optionsForZone =
        restrictedRegions.length === 0
          ? regionList[zone]
          : regionList[zone].filter(region => {
              // The ID of the region for British Council was not done the same as others.
              // Moving forward, all regions have the ID of their fully qualified URL. For now
              // it is easier to hard code this one exception than change the ID for a whole region.
              return (
                (region.id === "bc" &&
                  restrictedRegions.indexOf("bc.platform.sh") !== -1) ||
                restrictedRegions.indexOf(region.id) !== -1
              );
            });

      options.push({
        label: zone === "" ? "Other" : zone,
        value: zone === "" ? "Other" : zone,
        options: optionsForZone
      });
    });

    return (
      <Layout className="select-region">
        <Dropdown
          label="Region"
          options={options}
          defaultValue={
            !this.state.region && this.props.region
              ? this.props.region
              : this.state.region
          }
          searchable
          onChange={this.onChange}
          clearable={false}
          error={this.props.errors}
          fieldType={true}
          required={true}
          defaultText="Select datacenter region"
        />
      </Layout>
    );
  }
}

RegionPicker.propTypes = {
  loadRegions: PropTypes.func,
  onChange: PropTypes.func,
  regions: PropTypes.object,
  errors: PropTypes.object,
  region: PropTypes.object,
  vendor: PropTypes.bool,
  organizationId: PropTypes.string,
  regionOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = (state, props) => {
  let regionOptions = state.setup
    .getIn(["options", "data", "regions"], new Map())
    .toJS();
  let regions = state.region.get("byRegion", new Map());

  if (
    process.env.ENABLE_ORGANIZATION &&
    props.organizationId &&
    props.organizationId !== "projects"
  ) {
    regionOptions = state.organizationSetup
      .getIn([props.organizationId, "options", "data", "regions"], new Map())
      .toJS();

    regions = state.organizationRegion.getIn(
      ["byRegion", props.organizationId],
      new Map()
    );
  }
  return {
    regions,
    regionOptions
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadRegions: () => {
    if (
      process.env.ENABLE_ORGANIZATION &&
      props.organizationId &&
      props.organizationId !== "projects"
    ) {
      return dispatch(
        getOrganizationRegions({ organizationId: props.organizationId })
      );
    }

    dispatch(loadRegions());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionPicker);
