import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

const Paragraph = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  margin-bottom: 32px;
`;

export default Paragraph;
