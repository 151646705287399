import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Redirect from "Components/Redirect";

const withProjectRedirect = WrappedComponent => {
  const WithProjectRedirect = props => {
    const params = useParams();
    const orgByProjectId = useSelector(state =>
      state.project?.getIn([
        "orgByProjectId",
        props.projectId || params.projectId
      ])
    );
    const organizationId = props.organizationId || params.organizationId;
    const path = [
      "",
      orgByProjectId,
      ...window.location.pathname.slice(1).split("/").slice(1)
    ].join("/");

    return organizationId !== orgByProjectId &&
      typeof orgByProjectId !== "undefined" ? (
      <Redirect to={path} />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  WithProjectRedirect.displayName = `WithProjectRedirect(${getDisplayName(
    WrappedComponent
  )})`;

  WithProjectRedirect.propTypes = {
    organizationId: PropTypes.string,
    projectId: PropTypes.string
  };

  return WithProjectRedirect;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withProjectRedirect;
