import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Heading6 from "Components/styleguide/Heading6";
import Checkbox from "ds/Checkbox";

import { Layout } from "./styles";

const Show = ({ setValue, options }) => (
  <Layout>
    <Heading6>
      <FormattedMessage id="tickets.show" />
    </Heading6>
    {options &&
      options.map(({ id, isActive }) => (
        <Checkbox
          forId={`toggle-${id}`}
          key={id}
          checked={isActive}
          label={<FormattedMessage id={`tickets.status.${id}.label`} />}
          onCheckedChanged={isChecked => setValue({ [id]: isChecked })}
        />
      ))}
  </Layout>
);

Show.propTypes = {
  setValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      isActive: PropTypes.bool
    })
  )
};

export default Show;
