import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import CopyableArea from "Components/CopyableArea";

import { Description, LinkWrapper } from "../style";

const CLICommand = ({ command }) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <div>
      <Description>
        {formatMessage({ id: "cli.clone.description" })}
      </Description>
      <CopyableArea
        id="environment-cli-cmd"
        content={command}
        singleLine={true}
      >
        {command}
      </CopyableArea>

      <LinkWrapper>
        <a
          href={formatMessage({ id: "links.CLI_repository" })}
          rel="noopener noreferrer"
          target="_blank"
        >
          {formatMessage({ id: "cli.install" })}
        </a>
      </LinkWrapper>
    </div>
  );
};

CLICommand.propTypes = {
  command: PropTypes.string
};
export default CLICommand;
