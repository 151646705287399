import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";
import AccessibleTooltip from "Components/AccessibleTooltip";

export const CommitLayout = styled.div`
  list-style: none;
  padding: 0;
  padding-left: 37px;
  line-height: 24px;
  margin-bottom: 16px;
  display: flex;

  @media (min-width: 768px) {
    padding-left: 82px;
  }
`;

export const AvatarWithTooltip = styled(AccessibleTooltip)`
  margin-right: 16px;
`;

export const CommitMessage = styled.span`
  flex-grow: 1;
  margin-left: 8px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CommitSha = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

export const CommitDateLayout = styled.span`
  min-width: 156px;
  display: inline-block;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 12px;
  margin-right: 64px;
  text-overflow: ellipsis;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  ${semiBoldAlias};

  @media (min-width: 768px) {
    min-width: 140px;
  }
`;
