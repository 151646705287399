import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";

import { css, SECTION } from "Libs/themes";

import ModalWrapper from "Components/Modal";
import { Button, ButtonWrapper } from "ds/Button";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

const InvitationResendModal = ({
  isOpen,
  closeModal,
  email,
  resendInvitation
}) => {
  const intl = useIntl();

  return (
    <ModalWrapper
      modalClass="modal-medium"
      isOpen={isOpen}
      closeModal={closeModal}
      title={intl.formatMessage({ id: "invitation.resendModalTitle" })}
      id={"invitation-resend-modal"}
    >
      <ModalBody className="modal-body">
        <FormattedMessage
          id="invitation.resendModalBody"
          values={{
            email
          }}
        />
      </ModalBody>
      <ButtonWrapper hasSpacing justifyContent="end">
        <Button onClick={resendInvitation}>
          <FormattedMessage id="resend" />
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          <FormattedMessage id="cancel" />
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

InvitationResendModal.propTypes = {
  isOpen: PropTypes.bool,
  email: PropTypes.string,
  closeModal: PropTypes.func,
  resendInvitation: PropTypes.func
};

export default InvitationResendModal;
