import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";

import { getCSSVarString, ICON } from "Libs/themes";

import ListIcon from "Icons/ListIcon";
import GridIcon from "Icons/GridIcon";
import ToggleWrapper from "Components/ToggleWrapper";
import IconSortDirection from "Icons/IconSortDirection";
import Heading6 from "Components/styleguide/Heading6";
import RadioField from "Components/fields/RadioField";

import * as S from "./styles";

const DEFAULT_SORT_TYPES = [
  { name: "name", id: "project_title", ascend: "A-Z", descend: "Z-A" },
  { name: "region", id: "region_label", ascend: "A-Z", descend: "Z-A" },
  {
    name: "organization",
    id: "organization",
    ascend: "A-Z",
    descend: "Z-A"
  },
  {
    name: "organization",
    id: "owner",
    ascend: "A-Z",
    descend: "Z-A"
  },
  {
    name: "organization",
    id: "owner",
    ascend: "A-Z",
    descend: "Z-A"
  },
  {
    name: "date",
    id: "created_at",
    ascend: "recent last",
    descend: "recent first"
  },
  { name: "plan", id: "plan", ascend: "D-XXL", descend: "XXL-D" },
  { name: "id", id: "id", ascend: "0 - 1", descend: "1 - 0" }
];

const ToggleLayout = ({
  toggleLayout,
  gridLayout,
  sortType,
  setSortType,
  sortOrder,
  setSortOrder,
  sortTypes = DEFAULT_SORT_TYPES
}) => {
  const intl = useIntl();
  const selected = sortTypes.find(({ id }) => id === sortType) || sortTypes[0];

  return (
    <ToggleWrapper>
      <S.ProjectsSort
        id="projects-sort"
        label={`${intl.formatMessage({
          id: `projects.toggle.sort_by.${selected.name}`,
          defaultMessage: `Sort by ${selected.name}`
        })}`}
        ariaLabel={`${intl.formatMessage({
          id: `projects.toggle.sort_by.${selected.name}`,
          defaultMessage: `Sort by ${selected.name}`
        })}`}
        withArrow={false}
        withClose={true}
      >
        <Heading6 className="title">
          {intl.formatMessage({
            id: "projects.toggle.sort",
            defaultMessage: "Sort projects"
          })}
        </Heading6>
        <hr />
        {sortTypes.map(type => (
          <RadioField
            key={`sort-${type.id}`}
            forId={`sort-${type.id}`}
            name={name}
            label={`${intl.formatMessage({
              id: `projects.toggle.sort_by.${type.name}`,
              defaultMessage: `Sort by ${type.name}`
            })} (${type[sortOrder]})`}
            value={sortType === type.id}
            onChange={() => {
              setSortType(type.id);
            }}
          />
        ))}
      </S.ProjectsSort>
      <S.ToggleButton
        data-tip
        data-for="tooltip-reverse-sort"
        onClick={() => {
          setSortOrder(sortOrder === "ascend" ? "descend" : "ascend");
          document.activeElement.blur();
        }}
      >
        <ReactTooltip
          effect="solid"
          event="mouseover focus"
          eventOff="mouseout blur"
          place="bottom"
          id="tooltip-reverse-sort"
        >
          {intl.formatMessage({ id: "projects.sort.reverse" })}
        </ReactTooltip>
        <IconSortDirection
          color={"var(--icon-ebony-light-color,var(--icon-ebony-light,var(--ebony-light)))"}
          up={sortOrder === "descend"}
        />
      </S.ToggleButton>
      <button
        onClick={() => {
          toggleLayout(true);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "projects.grid_layout"
        })}
        className={`toggle-button${gridLayout ? " active" : ""}`}
      >
        <GridIcon color={"var(--icon-ebony-light-color,var(--icon-ebony-light,var(--ebony-light)))"} />
      </button>
      <button
        onClick={() => {
          toggleLayout(false);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "projects.list_layout"
        })}
        className={`toggle-button${gridLayout ? "" : " active"}`}
      >
        <ListIcon color={"var(--icon-ebony-light-color,var(--icon-ebony-light,var(--ebony-light)))"} />
      </button>
    </ToggleWrapper>
  );
};

ToggleLayout.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func,
  sortTypes: PropTypes.array
};

export default ToggleLayout;
