import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import InfoDialog from "../InfoDialog";

export const Layout = styled.div`
  grid-column: ${props => props.gridColumn};
  display: flex;
  flex-direction: column;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 15px;
  line-height: 18px;
  ${semiBoldAlias({ fontWeight: 400 })};
  &:first-child {
    text-transform: capitalize;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
  text-transform: capitalize ${semiBoldAlias};
`;

export const LabelDialog = styled(InfoDialog)`
  margin-left: 12px;
`;

export const Value = styled.span`
  text-transform: capitalize;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;
