import styled from "styled-components";
import { Link as UnstyledLink } from "react-router-dom";

import { semiBoldAlias } from "Libs/theme";
import {
  css,
  LINK,
  BUTTON,
  SECTION,
  ICON,
  getCSSVarString,
  MENU
} from "Libs/themes";

import UnstyledAvatar from "Components/Avatar";
import ActionDropdown from "Components/ActionDropdown";

const saneBoxSizing = `
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
`;

export const ActivityListItemLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 4px;
  border-radius: 2px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: ${props =>
    props.withShadow
      ? `var(--menu-box-shadow,var(--box-shadow))
    var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)))`
      : null};

  &:hover,
  &:focus-within {
    box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
      var(--menu-ebony-shadow-box-shadow-color,var(--menu-ebony-shadow,var(--ebony-shadow)));
    background-color:var(--activity-list-item-hover-section-background-color,var(--section-snow-background-color,var(--section-snow,var(--snow))));
  }
`;

export const Wrapper = styled.div`
  ${saneBoxSizing};
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  margin-right: 16px;
  line-height: 0;
  color:var(--icon-slate-color,var(--icon-slate,var(--slate)));

  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    .fill-target {
      fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
    }
    .stroke-target {
      stroke:var(--icon-slate-stroke,var(--icon-slate,var(--slate)));
    }
  }
`;

export const Avatar = styled(UnstyledAvatar)`
  margin-right: 24px;
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

// This div comes as HTML from the API
// That's why we use nested styles here
export const Event = styled.div`
  flex-grow: 1;
  line-height: 14px;
  overflow: hidden;

  strong a {
    font-weight: 700;
    text-decoration: none;
    color: inherit;
  }

  strong a:hover,
  strong a:focus {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;

export const Link = styled(UnstyledLink)`
  ${semiBoldAlias};
  padding-right: 10px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  color:var(--link-skye-color,var(--link-skye,var(--skye)));

  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  @media (min-width: 768px) {
    display: inline-block;
    padding-right: 0;
    padding-left: 10px;
    line-height: 1.4;
  }
`;

export const DetailsWrapper = styled.div`
  font-size: 12px;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
  display: flex;
  line-height: 18px;
  margin-top: 2px;
  white-space: nowrap;

  @media (min-width: 768px) {
    display: block;
    width: 100%;
  }
`;

export const EnvironmentLink = styled(UnstyledLink)`
  ${semiBoldAlias};
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  var(--section-slate-color,var(--section-slate,var(--slate)));
  margin-right: 8px;

  &:hover,
  &:focus {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;

export const Sha = styled.span``;

export const Message = styled.span`
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ActivityDate = styled.span`
  ${semiBoldAlias};
  min-width: 125px;
  margin-left: 16px;
  font-size: 12px;
  text-align: left;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));

  @media (min-width: 768px) {
    font-size: 13px;
    margin-right: 16px;
  }
`;

export const Duration = styled.span`
  ${semiBoldAlias};
  min-width: 56px;
  font-size: 12px;
  text-align: left;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));

  @media (min-width: 768px) {
    font-size: 13px;
    margin-right: 16px;
  }
`;

export const ActionButton = styled(ActionDropdown)`
  padding-left: 8px;
  .tooltip > section {
    padding: 8px 10px;
  }
`;

// @TODO: To be replaced with the design system dropdown layout
export const LogLink = styled.button`
  border: 1px solid transparent;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  &:first-letter {
    text-transform: uppercase;
  }
  color:var(--button-slate-color,var(--button-slate,var(--slate)));
  background: transparent;
  min-width: 72px;
  width: 100% !important;
  text-align: left;
  border-radius: 2px;
  padding: 0 12px;

  &:hover {
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
    background: ${getCSSVarString(
      BUTTON,
      "skye-light",
      "background-color"
    )};!important;
  }

  &:active {
    border: none;
    box-shadow: none;
    margin-right: 16px;
  }
`;

// Needed since the actiondropdown component
// that containst this link sets the padding for nested buttons
// with !important
export const LogLinkText = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

export const ShowMore = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Environment = styled(UnstyledLink)`
  font-weight: 600;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  text-decoration: none;
  color:var(--link-slate-color,var(--link-slate,var(--slate)));
`;
