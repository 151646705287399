import styled from "styled-components";
import Button from "ds/Button";

import { css, SECTION } from "Libs/themes";

export const Layout = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 24px 32px;
`;

export const EnvironmentPermissions = styled.div`
  hr {
    margin: 0;
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
`;

export const EnvironmentHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label + label {
    width: 33%;
  }
`;

export const PermissionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label + label {
    width: 33%;
  }
  label {
    font-size: 15px;
    font-weight: normal;
    height: 56px;
    line-height: 56px;
    margin-bottom: 8px;
  }
`;

export const InputLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const TableLine = styled.hr`
  margin-top: 0;
`;

export const ResendButton = styled(Button)`
  padding: 0px 8px;

  outline: none !important;
  border: 0 !important;

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    border: 0 !important;
    margin: 0 !important;
  }
`;

export const RevokeButton = styled(Button)`
  margin-top: 40px !important;
`;
