import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, LINK, getCSSVarString, SECTION, HEADER } from "Libs/themes";

import { Label, Value } from "Components/LabeledInfo/styles";

export const Wrapper = styled.div`
  position: absolute;
  display: block;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  padding: 16px 16px 24px 16px;
  height: auto !important;
  width: 262px !important;
  overflow: visible !important;
  box-shadow: ${props =>
    props.theme.name === "dark"
      ? "0px 8px 24px #1A192B"
      : "0px 6px 24px rgba(152, 160, 171, 0.4)"};
  z-index: 8;
  margin-top: 5px;
  border-radius: 4px;

  transform: ${props => props.transform};

  &:after {
    content: "";
    position: absolute;
    border: 5px solid transparent;
    border-bottom-color: white;
    top: -10px;
    left: 5%;
    margin-left: -5px;
    height: 0;
    width: 0;
  }

  ${Value} {
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  }

  ${Label} {
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 18px;
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 16px 0px;
  grid-row-gap: 16px;
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${Value} {
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
  }
`;

export const Link = styled.a.attrs({
  href: "#"
})`
  color:var(--link-skye-color,var(--link-skye,var(--skye)));
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  &:focus {
    outline: none;
    border: 1px solid var(--link-skye-color,var(--link-skye,var(--skye)));
    padding: 0 8px;
    border-radius: 4px;
  }
`;
