import styled from "styled-components";

import ChevronIcon from "Components/icons/ChevronIcon";
import Cell from "../Cell";
import Link from "../Link";

import { getCSSVarString, SECTION, LINK, css } from "Libs/themes";

export const Layout = styled(Cell).attrs({ as: "div" })`
  background: ${getCSSVarString(
    SECTION,
    "grey",
    "background-color"
  )} !important;
  padding-top: 0;
  padding-bottom: 8px;
  overflow: visible;
  width: ${props => (props.id === "subject" ? 100 : 1)}%;
`;

export const Header = styled.div`
  background: ${getCSSVarString(
    SECTION,
    "grey",
    "background-color"
  )} !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  &:hover:not(:focus):after {
    content: none;
  }
`;

export const HeaderSort = styled(Link).attrs({ as: "button" })`
  background: var(--link-grey-background-color,var(--link-grey,var(--grey))) !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  var(--section-slate-color,var(--section-slate,var(--slate)));
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  &:hover:not(:focus):after {
    content: none;
  }
`;

export const Chevron = styled(ChevronIcon)`
  width: 20px;
  margin-left: 4px;
  path {
    fill: ${({ isActive }) =>
      isActive
        ?"var(--section-skye-fill,var(--section-skye,var(--skye)))":"var(--section-slate-fill,var(--section-slate,var(--slate)))"};
  }
`;
