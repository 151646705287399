import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import Button from "ds/Button";
import InputField from "Components/fields/InputField";
import TextAreaField from "Components/fields/TextAreaField";
import Loading from "Components/Loading";

const SshKeyFormLayout = styled.div`
  padding: 24px 24px 32px;
  min-height: 200px;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;

  @media (min-width: 768px) {
    padding: 24px 32px 32px;
  }

  .primary {
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
  .rcs-custom-scroll {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 100%;
      box-sizing: boarder-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
  .toggle-field {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

class SshKeyForm extends React.Component {
  constructor(props) {
    super(props);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      ...this.props
    };
  }

  onChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  onTitleChange(event) {
    this.onChange("title", event.target.value);
  }

  onValueChange(event) {
    this.onChange("value", event.target.value);
  }

  onSave() {
    this.props.onSave(this.state);
  }

  onCancel() {
    this.props.onCancel();
  }

  render() {
    const { intl, isLoading, errors } = this.props;

    return (
      <Layout>
        <SshKeyFormLayout>
          <InputField
            id="sshkey-name-input"
            label={intl.formatMessage({ id: "title" })}
            value={this.state.title || ""}
            onChange={this.onTitleChange}
            error={errors.get && errors.get("title")}
          />
          <TextAreaField
            id="sshkey-value-input"
            label={intl.formatMessage({ id: "key" })}
            value={this.state.value || ""}
            onChange={this.onValueChange}
            error={errors.get && errors.get("value")}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <ButtonContainer>
              <Button id="save-sshkey-btn" onClick={this.onSave}>
                {intl.formatMessage({
                  id: "save"
                })}
              </Button>
              <Button
                id="cancel-sshkey-btn"
                variant="secondary"
                onClick={this.onCancel}
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ButtonContainer>
          )}
        </SshKeyFormLayout>
      </Layout>
    );
  }
}

SshKeyForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isJson: PropTypes.bool,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(SshKeyForm);
