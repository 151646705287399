import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useDecodedParams from "Hooks/useDecodedParams";
import useInput from "Hooks/useInput";
import { capitalize, isDefined } from "Libs/utils";

import { Button, ButtonWrapper } from "ds/Button";
import Error from "Components/Error";
import Loading from "Components/Loading";
import TextAreaField from "Components/fields/TextAreaField";
import InfoDialog from "Components/InfoDialog";

import * as S from "./VariableForm.styles";

const VariableForm = ({
  editable = true,
  environment,
  errors = {},
  isChanged,
  isChangedUpdate,
  isInactive = false,
  isLoading,
  isOverriddenFrom = false,
  linkToEdit,
  onCancel,
  onDelete,
  onSave,
  originEnv,
  variable
}) => {
  const intl = useIntl();
  const { organizationId, projectId } = useDecodedParams();

  const { inputs, handleInputChange } = useInput(
    {
      is_inheritable: isDefined(variable?.is_inheritable)
        ? variable?.is_inheritable
        : true,
      is_json: isDefined(variable?.is_json) ? variable?.is_json : false,
      is_sensitive: isDefined(variable?.is_sensitive)
        ? variable?.is_sensitive
        : false,
      is_enabled: isDefined(variable?.is_enabled) ? variable?.is_enabled : true,
      name: variable?.name || "",
      value: variable?.value || "",
      visible_build: isDefined(variable?.visible_build)
        ? variable?.visible_build
        : !environment,
      visible_runtime: isDefined(variable?.visible_runtime)
        ? variable?.visible_runtime
        : true
    },
    isChangedUpdate
  );
  const [valueFocused, setValueFocused] = useState(false);

  const isNew = !variable?.id;

  const showSaveAndCancelButtons = isChanged || isNew;
  const showDeleteButton =
    editable && variable?.hasPermission && variable?.hasPermission("#delete");

  const handleFocus = () => {
    setValueFocused(true);
  };

  const handleBlur = () => {
    setValueFocused(false);
  };

  const handleSave = e => {
    e?.preventDefault();

    if (isNew && (!inputs.name || !inputs.value)) return;

    // sensitive var doesn't display value
    if (!isNew && (!inputs.name || (!inputs.is_sensitive && !inputs.value)))
      return;

    let data = {
      id: variable?.id || null,
      name: inputs.name,
      value: inputs.value,
      is_json: inputs.is_json,
      is_sensitive: inputs.is_sensitive,
      visible_build: inputs.visible_build,
      visible_runtime: inputs.visible_runtime
    };
    if (environment) {
      data = Object.assign(data, {
        inherited: variable?.inherited || false,
        is_enabled: inputs.is_enabled,
        is_inheritable: inputs.is_inheritable
      });
    }
    onSave(data);
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={
          isNew
            ? intl.formatMessage({ id: "edit_variable" })
            : intl.formatMessage({ id: "add_variable" })
        }
        aria-live="polite"
      />

      <S.FormWrapper aria-labelledby="edit-variable-heading">
        {errors.error && <Error>{errors.error}</Error>}

        {isInactive && (
          <S.Inactive>
            {intl.formatMessage({ id: "settings.variables.inactive.details" })}
          </S.Inactive>
        )}
        {isOverriddenFrom ? (
          <S.Inherited>
            <S.OverriddenFrom
              to={`/${organizationId}/${projectId}/${
                isOverriddenFrom === "environment"
                  ? encodeURIComponent(originEnv)
                  : "-"
              }/settings/variables/${encodeURIComponent(variable.id)}`}
            >
              {intl.formatMessage(
                {
                  id: `settings.variables.overridden_from.${isOverriddenFrom}`
                },
                { from: originEnv }
              )}
            </S.OverriddenFrom>
          </S.Inherited>
        ) : (
          <>
            {environment &&
              !isNew &&
              (variable?.inherited || !variable?.is_enabled) && (
                <S.Inherited>
                  <S.InheritedFrom
                    to={`/${organizationId}/${projectId}/${encodeURIComponent(
                      originEnv
                    )}/settings/variables/${encodeURIComponent(variable.id)}`}
                  >
                    {intl.formatMessage(
                      { id: "settings.variables.inherited_from" },
                      { from: originEnv }
                    )}
                  </S.InheritedFrom>
                  <S.Toggle>
                    <label htmlFor="is_enabled">
                      {intl.formatMessage({
                        id: "settings.variables.enabled"
                      })}
                    </label>
                    <input
                      type="checkbox"
                      id="is_enabled"
                      name="is_enabled"
                      checked={inputs.is_enabled}
                      onChange={elt => handleInputChange(elt)}
                    />
                  </S.Toggle>
                </S.Inherited>
              )}
          </>
        )}

        <S.InputWrapper>
          <S.Input
            id="name"
            label={intl.formatMessage({ id: "settings.variables.name" })}
            value={inputs.name}
            onChange={elt => handleInputChange(elt)}
            error={errors["name"]}
            isDisabled={!isNew}
          />
          {!isNew && (
            <S.InputDialog
              text={intl.formatMessage({
                id: "settings.variables.edit_name"
              })}
              linkText={intl.formatMessage({
                id: "learnmore"
              })}
              to={intl.formatMessage({
                id: "links.documentation.variables"
              })}
            />
          )}
        </S.InputWrapper>

        <S.InputWrapper>
          <TextAreaField
            id="value"
            label={intl.formatMessage({ id: "value" })}
            value={
              variable?.is_sensitive && !valueFocused && !inputs.value
                ? "*****"
                : inputs.value
            }
            onChange={elt => handleInputChange(elt)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={errors["value"]}
            isDisabled={!editable}
          />
        </S.InputWrapper>

        <S.Option>
          <S.Checkbox
            forId="visible_runtime"
            label={intl.formatMessage(
              {
                id: "settings.variables.during_run"
              },
              {
                b: (...chunks) => <strong>{chunks}</strong> // eslint-disable-line react/display-name
              }
            )}
            value={inputs.visible_runtime}
            onChange={elt => handleInputChange(elt)}
            disabled={!editable}
          />
          <InfoDialog
            title={intl.formatMessage({ id: "learnmore" })}
            text={intl.formatMessage({
              id: "settings.variables.during_run.more"
            })}
            to={intl.formatMessage({
              id: "links.documentation.variables.run_and_build_time"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
          />
        </S.Option>

        <S.Option>
          <S.Checkbox
            forId="visible_build"
            label={intl.formatMessage(
              {
                id: "settings.variables.during_build"
              },
              {
                b: (...chunks) => <strong>{chunks}</strong>, // eslint-disable-line react/display-name
                code: (...chunks) => <code>{chunks}</code> // eslint-disable-line react/display-name
              }
            )}
            value={inputs.visible_build}
            onChange={elt => handleInputChange(elt)}
            disabled={!editable}
          />
          <InfoDialog
            title={intl.formatMessage({ id: "learnmore" })}
            text={intl.formatMessage({
              id: "settings.variables.during_build.more"
            })}
            to={intl.formatMessage({
              id: "links.documentation.variables.run_and_build_time"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
          />
        </S.Option>

        <S.Option>
          <S.Checkbox
            forId="is_json"
            label={intl.formatMessage(
              {
                id: "settings.variables.is_json"
              },
              {
                b: (...chunks) => <strong>{chunks}</strong> // eslint-disable-line react/display-name
              }
            )}
            value={inputs.is_json}
            onChange={elt => handleInputChange(elt)}
            disabled={!editable}
          />{" "}
          <InfoDialog
            title={intl.formatMessage({ id: "learnmore" })}
            text={intl.formatMessage({
              id: "settings.variables.is_json.more"
            })}
            to={intl.formatMessage({
              id: "links.documentation.variables.run_and_build_time"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
          />
        </S.Option>

        <S.Option>
          <S.Checkbox
            forId="is_sensitive"
            label={intl.formatMessage(
              {
                id: "settings.variables.sensitive"
              },
              {
                b: (...chunks) => <strong>{chunks}</strong> // eslint-disable-line react/display-name
              }
            )}
            value={inputs.is_sensitive}
            onChange={elt => handleInputChange(elt)}
            disabled={!editable || variable?.is_sensitive}
          />{" "}
          <InfoDialog
            title={intl.formatMessage({ id: "learnmore" })}
            text={intl.formatMessage({
              id: "settings.variables.sensitive.more"
            })}
            to={intl.formatMessage({
              id: "links.documentation.variables"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
          />
        </S.Option>

        {environment && (
          <S.Option>
            <S.Checkbox
              forId="is_inheritable"
              label={intl.formatMessage(
                {
                  id: "settings.variables.inheritable"
                },
                {
                  b: (...chunks) => <strong>{chunks}</strong> // eslint-disable-line react/display-name
                }
              )}
              value={inputs.is_inheritable}
              onChange={elt => handleInputChange(elt)}
              disbaled={!editable}
            />{" "}
            <InfoDialog
              title={intl.formatMessage({ id: "learnmore" })}
              text={intl.formatMessage({
                id: "settings.variables.inheritable.more"
              })}
              to={intl.formatMessage({
                id: "links.documentation.variables"
              })}
              linkText={intl.formatMessage({ id: "learnmore" })}
            />
          </S.Option>
        )}

        {!isNew && showSaveAndCancelButtons && (
          <S.Warning>
            <S.WarningIcon />{" "}
            {intl.formatMessage({
              id: `settings.${
                environment ? "environment." : ""
              }variables.warning${variable?.inherited ? ".child" : ""}`
            })}
          </S.Warning>
        )}

        <ButtonWrapper hasSpacing>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!editable && (
                <Button id="edit-variable-btn" to={linkToEdit}>
                  {capitalize(
                    intl.formatMessage({
                      id: "edit"
                    })
                  )}
                </Button>
              )}

              {showSaveAndCancelButtons && (
                <>
                  <Button
                    id="save-variable-btn"
                    type="submit"
                    onClick={handleSave}
                  >
                    {capitalize(
                      intl.formatMessage({
                        id: isNew
                          ? "add_variable"
                          : variable?.inherited
                          ? "settings.variables.override"
                          : "save"
                      })
                    )}
                  </Button>
                  <Button
                    id="cancel-variable-btn"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    {capitalize(intl.formatMessage({ id: "cancel" }))}
                  </Button>
                </>
              )}

              {showDeleteButton && (
                <S.DeleteButton
                  variant="outline"
                  id={`variable-list-${variable.name}-delete-btn`}
                  onClick={onDelete}
                >
                  {capitalize(intl.formatMessage({ id: "delete" }))}
                </S.DeleteButton>
              )}
            </>
          )}
        </ButtonWrapper>
      </S.FormWrapper>
    </S.Wrapper>
  );
};

VariableForm.propTypes = {
  editable: PropTypes.bool,
  environment: PropTypes.object,
  errors: PropTypes.object,
  isChanged: PropTypes.bool,
  isChangedUpdate: PropTypes.func,
  isInactive: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOverriddenFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  linkToEdit: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  originEnv: PropTypes.string,
  variable: PropTypes.object
};

export default VariableForm;
