import styled, { css as styledCSS } from "styled-components";
import { css, LINK, SECTION } from "Libs/themes";

export default styled.div`
  font-size: 14px;
  line-height: 26px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  margin-bottom: 32px;
  ${({ fullWidth }) =>
    !fullWidth &&
    styledCSS`
      max-width: 840px;
    `};
  .info-dialog {
    float: left;
    margin: 0 8px 0 -4px;
  }
  p {
    line-height: 24px;
    margin-bottom: 16px !important;
  }
  a {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
