import styled from "styled-components";
import { css, ICON } from "Libs/themes";

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  box-shadow: 0px 1px 4px rgba(152, 160, 171, 0.4);
  border-radius: 2px;
  padding: 4px 4px 4px 8px;
`;

export const CloseTagButton = styled.button`
  display: flex;

  background-color: white;
  border: none;
  cursor: pointer;

  svg {
    height: 10px;
    width: 10px;
    > g > g {
      fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
    }
  }

  &:hover {
    svg {
      > g > g {
        fill:var(--icon-periwinkle-grey-fill,var(--icon-periwinkle-grey,var(--periwinkle-grey)));
      }
    }
  }
`;
