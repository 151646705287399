import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Map } from "immutable";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";
import { loadDeployment } from "Reducers/environment/deployment";

import Tree from "./ServicesTree";
import List from "./ServicesList";
import EmptyTree from "./EmptyTree";

import * as S from "./styles";

const ServicesDisplay = ({
  hasInfoTooltip,
  height,
  listMode,
  mainEnvironmentId,
  maxHeight,
  minHeight,
  strokeColor,
  treePositionY,
  width,
  onLoadEnd
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { appName, environmentId, organizationId, projectId } =
    useDecodedParams();
  const envId = environmentId || mainEnvironmentId;

  const [scrollLeft, setScrollLeft] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [isMouseDown, setMouseDown] = useState(false);
  const [startCursorX, setStartCursorX] = useState(false);
  const [layoutRef, setLayoutRef] = useState(false);

  const environment = useSelectorWithUrlParams(environmentSelector, {
    environmentId: envId
  });
  const isEnvironmentLoading = useSelectorWithUrlParams(
    environmentLoadingSelector,
    {
      environmentId: envId
    }
  );

  const currentDeployment = useSelector(state =>
    state.deployment?.getIn(
      ["data", organizationId, projectId, envId, "current"],
      Map()
    )
  );

  const currentDeploymentError = useSelector(state =>
    state.deployment?.get("errors")
  );

  useEffect(() => {
    if (environment?.data?.has_code) {
      dispatch(loadDeployment(organizationId, projectId, envId));
    }
  }, [environment?.id]);

  useEffect(() => {
    if (!onLoadEnd) return;
    if (
      currentDeploymentError ||
      (!isEnvironmentLoading && !environment?.data?.has_code)
    )
      onLoadEnd();
  }, [currentDeploymentError, isEnvironmentLoading, environment, onLoadEnd]);

  const openNode = node => {
    if (hasInfoTooltip) return;
    goToService(node.metadata);
  };

  const goToService = meta => {
    // appName for worker is format like appName--workerName
    const service = meta.worker
      ? meta.appName.split("--")[0]
      : meta.appName || "routes";
    push(
      `/${organizationId}/${projectId}/${encodeURIComponent(
        envId
      )}/services/${service}`
    );
  };

  const onMouseDown = e => {
    e.preventDefault();
    setStartCursorX(e.clientX);
    setMouseDown(true);
  };

  const onMouseUp = e => {
    e.preventDefault();
    setStartCursorX(false);
    setMouseDown(false);
  };

  const onMouseMove = e => {
    e.preventDefault();
    if (!startCursorX || isMouseDown) {
      return false;
    }

    const endCursorX = e.clientX;
    const scrollLeft = scrollLeft + (startCursorX - endCursorX);
    setStartCursorX(endCursorX);
    layoutRef.scrollLeft = scrollLeft;
  };

  const onMouseOut = e => {
    e.preventDefault();
    onMouseMove(e);
    onMouseUp(e);
  };

  if (currentDeploymentError) {
    return (
      <EmptyTree>
        <FormattedMessage id="services.display.error" />
      </EmptyTree>
    );
  }

  if (!isEnvironmentLoading && !environment?.data?.has_code) {
    return (
      <EmptyTree>
        <FormattedMessage id="services.display.empty" />
      </EmptyTree>
    );
  }

  return (
    <S.Layout
      onScroll={e => setScrollLeft(e.target.scrollLeft)}
      onMouseUp={e => onMouseUp(e)}
      onMouseDown={e => onMouseDown(e)}
      onMouseMove={e => onMouseMove(e)}
      onMouseOut={e => onMouseOut(e)}
      ref={ref => setLayoutRef(ref)}
      dragged={isMouseDown}
      onBlur={e => onMouseOut(e)}
      listMode={listMode}
      isScroll={isScroll}
    >
      {listMode ? (
        <List
          onClick={node => openNode(node)}
          currentDeployment={currentDeployment.toJS()}
          search={""}
          selected={appName || "routes"}
        />
      ) : (
        <Tree
          onClick={node => openNode(node)}
          goToService={goToService}
          currentDeployment={currentDeployment}
          strokeColor={strokeColor}
          onNeedScroll={needScroll => setIsScroll(needScroll)}
          treePositionY={treePositionY}
          leftOffset={scrollLeft}
          height={height}
          width={width}
          minHeight={minHeight}
          maxHeight={maxHeight}
          hasInfoTooltip={hasInfoTooltip}
          onLoadEnd={onLoadEnd}
        />
      )}
    </S.Layout>
  );
};

ServicesDisplay.propTypes = {
  hasInfoTooltip: PropTypes.bool,
  height: PropTypes.string,
  listMode: PropTypes.bool,
  mainEnvironmentId: PropTypes.string,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.string,
  strokeColor: PropTypes.string,
  treePositionY: PropTypes.number,
  width: PropTypes.string,
  onLoadEnd: PropTypes.func
};

export default withReducers({
  deployment: () => import("Reducers/environment/deployment"),
  environment: () => import("Reducers/environment")
})(ServicesDisplay);
