import styled from "styled-components";
import { css, SECTION, getCSSVarString } from "Libs/themes";

import { semiBoldAlias } from "Libs/theme";

export const ExtraEnvironmentsList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const EnvironmentName = styled.div`
  margin-bottom: 8px;
`;

export const Layout = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
  text-transform: none;

  .dialog {
    width: 200px;
  }

  .dialog > div {
    width: 200px;
    padding-top: 8px;
    padding-bottom: 0px;
  }

  .align-center {
    left: 12px;
    margin-left: -100px;
  }
`;

export const ExtraNumber = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  height: 16px;
  line-height: 20px;
  background-color:var(--section-grey-background-color,var(--section-grey,var(--grey)));
  font-size: 12px;
  border-radius: 2px;
  ${semiBoldAlias()};
  var(--section-slate-color,var(--section-slate,var(--slate)));
  width: 14px;

  &:hover {
    background-color:var(--section-slate-background-color,var(--section-slate,var(--slate)));
    color:var(--section-snow-color,var(--section-snow,var(--snow)));
  }
`;

export const TreeDot = styled.span`
  margin-right: 16px;
`;
