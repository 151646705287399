import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export const Layout = styled.div`
  display: block;
  width: 100%;
  min-width: 200px;
  cursor: ${props =>
    !props.listMode && props.isScroll && (props.dragged ? "grabbing" : "grab")};
  background: ${props => props.background || "inherit"};
  scrollbar-width: thin;
  overflow: visible;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color:var(--section-granite-background-color,var(--section-granite,var(--granite)));
    height: 4px;
  }

  ${props =>
    !props.listMode &&
    `
      background: transparent;
      border-radius: 4px;
      overflow: hidden;
    `};
`;
