import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { getCSSVarString, SECTION } from "Libs/themes";

const TableLayout = styled.div`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
`;

const TableHeader = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.fontSize || "13"}px;
  text-align: ${props => props.textAlign || "left"};
  line-height: 18px;
  padding-bottom: 8px;
  width: 100%;
  ${semiBoldAlias};
`;

const TableRowHeader = styled.div`
  border-bottom: 1px solid
    var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  display: flex;
  justify-content: stretch;
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px;
`;

const TableFooter = styled.footer`
  background: #f5f7fa;
  margin: 0 16px;
`;

const getClass = value => {
  return value.replace(" ", "-").toLowerCase();
};

const Table = ({
  headers,
  children,
  fontSize,
  textAlign,
  footer,
  className
}) => {
  return (
    <TableLayout
      className={`table-layout${className ? " " + className : ""}`}
      fontSize={fontSize}
    >
      {headers && (
        <TableRowHeader className="table-row-header">
          {headers.map(header => (
            <TableHeader
              className={`cell ${getClass(header)}`}
              key={header}
              color="#4b6180"
              textAlign={textAlign}
            >
              {header}
            </TableHeader>
          ))}
        </TableRowHeader>
      )}
      {children}
      {footer && <TableFooter>{footer}</TableFooter>}
    </TableLayout>
  );
};

Table.propTypes = {
  headers: PropTypes.array,
  children: PropTypes.node,
  fontSize: PropTypes.number,
  textAlign: PropTypes.string,
  className: PropTypes.string,
  footer: PropTypes.element
};

export default Table;
