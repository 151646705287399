import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";

import Loading from "Components/Loading";
import Checkbox from "./Checkbox";
import ActionDropdown from "Components/ActionDropdown";
import IconMore from "Icons/IconMore";
import useMediaQuery from "Hooks/useMediaQuery";
import { breakpoints } from "Libs/theme";

import HeaderCell from "./HeaderCell";

import * as S from "./Table.styles";

const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH":
      return {
        ...state,
        search: action.value
      };
    case "SORT":
      return {
        ...state,
        direction:
          state.sortBy === action.value && state.direction === "DESC"
            ? "ASC"
            : "DESC",
        sortBy: action.value
      };
    case "SHOW":
      return {
        ...state,
        show: {
          ...state.show,
          ...action.value
        }
      };
    default:
      return state;
  }
};

const Datatable = ({
  data = [],
  height = 700,
  isLoading,
  hasMore,
  loadMore,
  error,
  onFilterChange = () => {},
  headers = [],
  renderCell,
  onMenuClick = () => {},
  menuItems = [],
  menuWidth = 140,
  emptyState = <div>Empty</div>,
  multiple = false
}) => {
  const [isSelectAll, selectAll] = useState(false);
  const [selected, select] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    sortBy: "id",
    direction: "DESC"
  });

  const widerThan768 = useMediaQuery(`(min-width: ${breakpoints[1]})`);

  const filteredHeaders = widerThan768
    ? headers
    : headers.filter(h => h.showOnMobile);

  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  const selectAllLines = all => {
    if (all) {
      select(data.map((_, i) => i));
    } else {
      select([]);
    }

    selectAll(all);
  };

  const selectLine = index => {
    const selectedArrayIndex = selected.indexOf(index);
    if (selectedArrayIndex === -1) {
      return select([...selected, index]);
    }

    const tmp = [...selected];
    tmp.splice(selectedArrayIndex, 1);
    select(tmp);
  };

  const handleMenuClick = (e, index, item) => {
    e.preventDefault();
    e.stopPropagation();

    onMenuClick(index, item);
  };

  const scrollEvent = new Event("scrolled");

  const getMenuItems = item => {
    if (typeof menuItems === "function") {
      return menuItems(item);
    }

    return menuItems;
  };

  const dispatch = action => {
    setFilters(reducer(filters, action));
  };

  return (
    <>
      {error && <div>{error.message}</div>}
      <div>
        <div style={{ display: "flex" }}>
          {multiple && (
            <S.CheckBoxWrapper>
              <Checkbox
                type="checkbox"
                checked={isSelectAll}
                onChange={v => selectAllLines(v)}
              />
            </S.CheckBoxWrapper>
          )}
          <S.HeaderRow
            firstCellWidth={filteredHeaders[0].width}
            multiple={multiple}
          >
            {filteredHeaders.map(h => (
              <HeaderCell
                key={`${h.name}-header`}
                onClick={dispatch}
                isActive={filters.sortBy === h.name}
                direction={filters.direction}
                title={h.label || h.name}
                id={h.id}
                sortable={h.sortable}
                width={h.width}
              />
            ))}
          </S.HeaderRow>
        </div>
        <div
          style={{ height, overflow: "auto" }}
          onScroll={() => document.dispatchEvent(scrollEvent)}
        >
          <InfiniteScroll
            threshold={400}
            initialLoad={false}
            loadMore={loadMore}
            hasMore={!isLoading && hasMore}
            useWindow={false}
            loader={<Loading key="member-loader" />}
          >
            {data?.length
              ? data.map((d, lineIndex) => {
                  const isSelected = selected.indexOf(lineIndex) !== -1;
                  const computedMenuItems = getMenuItems(d);
                  return (
                    <S.BodyRow
                      key={`${d.id}-row`}
                      firstCellWidth={headers[0].width}
                      lastCellWidth={headers[headers.length - 1].width}
                      className={isSelected && "selected"}
                      multiple={multiple}
                    >
                      <div style={{ marginLeft: -16 }}>
                        <Checkbox
                          type="checkbox"
                          checked={isSelected}
                          onChange={v => selectLine(lineIndex, v)}
                        />
                      </div>
                      {headers.map(
                        (h, i) =>
                          (renderCell && renderCell(h, i, d, lineIndex)) || (
                            <S.BodyCell
                              key={`${h.name}-value-${i}`}
                              width={h.width}
                              index={i}
                            >
                              {d[h.name]}
                            </S.BodyCell>
                          )
                      )}
                      {computedMenuItems?.length ? (
                        <ActionDropdown
                          icon={<IconMore color="black" />}
                          ariaLabel="More"
                          withArrow={false}
                          className="more"
                          id={d.id}
                          withPortal={true}
                          tooltipWidth={menuWidth}
                        >
                          <S.MenuList>
                            {computedMenuItems.map((item, index) => (
                              <li role="menuitem" key={item}>
                                <S.MenuItem
                                  onClick={e => handleMenuClick(e, index, d)}
                                >
                                  {item}
                                </S.MenuItem>
                              </li>
                            ))}
                          </S.MenuList>
                        </ActionDropdown>
                      ) : (
                        <S.ActionDropdownPlaceholder />
                      )}
                    </S.BodyRow>
                  );
                })
              : emptyState}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

Datatable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  multiple: PropTypes.bool,
  loadMore: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  className: PropTypes.string,
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  menuWidth: PropTypes.number,
  error: PropTypes.object,
  onFilterChange: PropTypes.func,
  sortBy: PropTypes.array,
  menuItems: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  renderCell: PropTypes.func,
  onMenuClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emptyState: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Datatable;
