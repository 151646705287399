import React from "react";

import useUniqueId from "Libs/useUniqueId";

const WorkerIcon = () => {
  const uid = useUniqueId();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`worker-icon-${uid}`}
      role="img"
    >
      <title id={`worker-icon-${uid}`}>Worker icon</title>
      <circle cx="8" cy="8" r="8" fill="#C9D0E4" />
      <path
        d="M12.2876 5.30078H10.875L10.0264 9.50635H9.96729L8.8877 5.30078H7.55566L6.47607 9.50635H6.42236L5.57373 5.30078H4.14502L5.60059 10.9995H7.09912L8.19482 6.94434H8.23779L9.3335 10.9995H10.8267L12.2876 5.30078Z"
        fill="#1A192B"
      />
    </svg>
  );
};

export default WorkerIcon;
