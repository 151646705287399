import styled from "styled-components";
import {
  css,
  BUTTON,
  SECTION,
  ICON,
  INPUT,
  getCSSVarString
} from "Libs/themes";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  max-width: 100%;
  min-width: 264px;
  margin-bottom: 32px;
  .input-required {
    border: 1px solid border-color:var(--input-red-border-color,var(--input-red,var(--red)));
  }
  .required-message {
    display: inline-block;
    color:var(--section-red-color,var(--section-red,var(--red)));
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .CodeMirror {
    border-radius: 2px;
    background-color: ${getCSSVarString(
      SECTION,
      "night",
      "background-color"
    )} !important;
  }

  .CodeMirror-lines {
    padding-left: 26px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  padding: 0;
`;

export const Button = styled.div`
  cursor: pointer;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  border: none;
  background: none;
  svg path {
    fill:var(--icon-periwinkle-grey-fill,var(--icon-periwinkle-grey,var(--periwinkle-grey)));
  }
  &:hover svg path {
    fill:var(--button-ebony-light-fill,var(--button-ebony-light,var(--ebony-light)));
  }
`;
