import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const ListIcon = ({ color }) => {
  const uid = useUniqueId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 22"
      height="24"
      width="24"
      role="img"
      aria-labelledby={`list-icon-${uid}`}
    >
      <title id={`list-icon-${uid}`}>List icon</title>
      <defs>
        <clipPath id={`link-icon-a-${uid}`}>
          <path
            d="M5,5V7H19V5Zm14,7V10H5v2ZM5,17H19V15H5Z"
            style={{ fill: "none", clipRule: "evenodd" }}
          />
        </clipPath>
        <clipPath id={`link-icon-b-${uid}`}>
          <rect width="24" height="22" style={{ fill: "none" }} />
        </clipPath>
        <clipPath id={`link-icon-c-${uid}`}>
          <rect x="5" y="5" width="14" height="12" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: `url(#link-icon-a-${uid})` }}>
        <g style={{ clipPath: `url(#link-icon-b-${uid})` }}>
          <g style={{ clipPath: `url(#link-icon-c-${uid})` }}>
            <rect width="24" height="22" style={{ fill: color }} />
          </g>
        </g>
      </g>
    </svg>
  );
};

ListIcon.propTypes = {
  color: PropTypes.string
};

export default ListIcon;
