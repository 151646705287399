import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export default styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  &.settings-wrapper {
    padding-top: 0;
  }
  &.setting-return {
    padding-top: 0;
    margin-top: -16px;
  }
  code {
    color:var(--section-skye-dark-color,var(--section-skye-dark,var(--skye-dark)));
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
`;
