import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { isJson } from "Libs/utils";

import { request } from "Libs/platform";
import logger from "Libs/logger";
import { SCREENSHOT_API } from "Constants/constants";
import NoImageFound from "./img/no_image.svg";

import ScreenshotPlaceholder from "./img/placeholder.png";

import * as S from "./styles";

const isCommonError = error => {
  const commonErrors = [
    "404 page not found",
    "Access Denied.",
    "Project not found."
  ];

  try {
    const parsedError = isJson(error) ? JSON.parse(error).error : error;
    return commonErrors.includes(parsedError);
  } catch (error) {
    return false;
  }
};

const arrayBufferToBase64 = buffer => {
  let binary = "";
  const bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach(b => (binary += String.fromCharCode(b)));

  return window.btoa(binary);
};

const Screenshot = ({ imagePath }) => {
  const [isLoading, setLoading] = useState(false);
  const [screenshotPath, setScreenshotPath] = useState(false);
  const [previewErr, setPreviewErr] = useState("");

  const noPreviewMessages = {
    "project not found": "The project is not found",
    "no deployment": "The project is not deployed successfully",
    "no code": "The project has no code",
    "website not found": "The application is not responding",
    "access denied": "Your project access is denied"
  };

  const intl = useIntl();

  useEffect(() => {
    let isCanceled = false;
    const loadScreenshot = (retryNumber = 0) => {
      return request(`${SCREENSHOT_API}${imagePath}`, "GET")
        .then(response => {
          if (response.status === 202) {
            // Allow retry for 1 minute, the interval increase at each retry
            if (retryNumber < 12) {
              return setTimeout(
                () => loadScreenshot(++retryNumber),
                5000 * (retryNumber + 1)
              );
            }
            if (isCanceled) {
              return;
            }
            setLoading(false);
            return;
          }

          response.arrayBuffer().then(buffer => {
            const imageStr = arrayBufferToBase64(buffer);
            if (isCanceled) {
              return;
            }
            if (imageStr.length)
              setScreenshotPath(`data:image/jpeg;base64,${imageStr}`);
            setLoading(false);
          });
        })
        .catch(err => {
          const errorName = JSON.parse(err)
            .error.replace(/[.]/g, "")
            .toLowerCase();
          setPreviewErr(errorName);
          console.log(err); // eslint-disable-line no-console
          if (isCanceled) {
            return;
          }
          setLoading(false);
          if (!isCommonError(err)) {
            logger(err, {
              action: "screenshot_fetch"
            });
          }
        });
    };

    const timer = loadScreenshot();
    return () => {
      isCanceled = true;
      clearTimeout(timer);
    };
  }, [imagePath]);

  return (
    <S.Wrapper
      className={`screenshot ${screenshotPath ? "" : "no-screenshot"}`}
    >
      {isLoading || screenshotPath ? (
        <S.Image>
          <img
            src={isLoading ? ScreenshotPlaceholder : screenshotPath}
            alt={intl.formatMessage({
              id: isLoading ? "loading" : "screenshot.available"
            })}
          />
        </S.Image>
      ) : (
        <S.NoImage
          previewMessage={noPreviewMessages[previewErr] ?? "Loading..."}
        >
          <img
            src={NoImageFound}
            alt={intl.formatMessage({
              id: "screenshot.notAvailable",
              defaultMessage: "Image not available"
            })}
          />
        </S.NoImage>
      )}
    </S.Wrapper>
  );
};

Screenshot.propTypes = {
  imagePath: PropTypes.string
};

export default Screenshot;
