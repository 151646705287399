import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import client from "Libs/platform";
import logger from "Libs/logger";
import { hasHtml } from "Libs/utils";

import { MOMENTJS_OPTIONS } from "Constants/constants";

import activityTemplates from "../../activityTemplates";

import PlatformAvatar from "Images/platform-avatar.svg";
import ActivityDate from "../ActivityDate";
import StatusIcon from "../StatusIcon";

import * as S from "./ActivityHeader.styles";

const ActivityHeader = ({ activity, className = "" }) => {
  const [avatarPicture, setAvatarPicture] = useState();

  momentjs.updateLocale("en", MOMENTJS_OPTIONS);

  const { completed_at, created_at, payload, result, state, started_at, type } =
    activity;

  // fetchUserAvatar handles fetching avatars for the user associated to
  // the activity. It deals with special cases like the user not having
  // permissions to get other users info, or special users like the Bots
  const fetchUserAvatar = () => {
    const user = payload?.user;
    if (!user) return;

    const isIntegrationUser = user.id.startsWith("integration:");
    const isAdmin = user.id === "admin";
    const isRegularUser = !isAdmin && !isIntegrationUser;

    // Admin is an special kind of user, we are not getting an avatar
    // for it, but we want to show the logo instead of the default avatar
    // so we set the picture here
    if (isAdmin) {
      setAvatarPicture(PlatformAvatar);
    }

    // We fetch avatars for regular users here
    if (isRegularUser) {
      client
        .getAccount(user.id)
        .then(account => {
          setAvatarPicture(account.picture);
        })
        .catch(error => {
          // ignores 404 errors from the api, usually happens when the requester doesn't
          // have permission to fetch the user profile. Or the profie does not exist.

          if (![404, 403].includes(error.code) && !hasHtml(error)) {
            logger(error, {
              action: "avatar_fetch"
            });
          }
        });
    }
  };

  useEffect(fetchUserAvatar, [payload]);

  const [icon, title] = useMemo(() => {
    const tpl = activityTemplates[type] || activityTemplates.unknown;
    const title = tpl.title && tpl.title({ activity });
    return [tpl.icon, title];
  }, []);

  const lastCommit = useMemo(() => {
    return payload?.commits?.slice(-1).pop();
  }, [payload?.commits]);

  const duration = useMemo(() => {
    return completed_at
      ? momentjs
          .duration(momentjs(completed_at).diff(momentjs(started_at)))
          .humanize({ s: 60, m: 60, h: 24, d: 28, M: 11 })
      : "";
  }, []);

  return (
    <S.Wrapper className={`${className} activity-header-wrapper`}>
      <S.Content>
        <S.Left>
          <S.Icon>{icon}</S.Icon>
          <S.Avatar
            name={payload?.user?.display_name}
            picture={avatarPicture}
          />

          <S.Event>
            <S.Title>{title}</S.Title>
            {lastCommit && (
              <S.DetailsWrapper>
                <S.Sha>{lastCommit.sha?.substring(0, 7)}</S.Sha>
                <S.Message>{lastCommit?.message}</S.Message>
              </S.DetailsWrapper>
            )}
          </S.Event>

          <S.DateTime>
            <S.Duration>{duration}</S.Duration>
            <S.Date>
              <ActivityDate date={created_at} activityId={activity.id} />
            </S.Date>
          </S.DateTime>
        </S.Left>

        <StatusIcon state={state} result={result} />
      </S.Content>
    </S.Wrapper>
  );
};

ActivityHeader.propTypes = {
  activity: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default ActivityHeader;
