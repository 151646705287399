import styled, { css as styledCSS } from "styled-components";

import { css, SECTION } from "Libs/themes";

const Pane = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));

  ${({ theme, isOpaque, hasShadow }) =>
    theme.name === "dark"
      ? styledCSS`
          ${hasShadow && `box-shadow: 0px 6px 44px #01001a;`};
          ${isOpaque && `background-color: #302F45`};
        `
      : styledCSS`
          ${hasShadow && `box-shadow: 0px 10px 20px rgba(75, 97, 128, 0.08);`};
        `};
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border-radius: 2px;

  @media screen and (min-width: 768px) {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export default Pane;
