import styled from "styled-components";
import { getCSSVarString, SECTION } from "Libs/themes";

const TableRow = styled.div`
  height: 56px;
  width: calc(100% - 64px);
  margin: 0 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: stretch;
  border-bottom: 1px
    var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  &:last-of-type {
    border-bottom: none;
  }
`;

export default TableRow;
