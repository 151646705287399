import styled from "styled-components";

import {
  getCSSVarString,
  SECTION,
  css,
  HEADER,
  ICON,
  BUTTON
} from "Libs/themes";

import AccessibleTooltip from "Components/AccessibleTooltip";

export const Wrapper = styled.div`
  margin: 24px 0 0 0;
  border: 1px solid var(--section-ebony-border-color,var(--section-ebony,var(--ebony)));
  pre {
    code {
      padding-left: 4px;
      background: inherit;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  margin: 0;
  padding: 0 16px;
  background-color:var(--header-ebony-background-color,var(--header-ebony,var(--ebony)));
  line-height: 40px;
  h1 {
    margin: 0;
    font-size: 12px;
    color:var(--header-snow-color,var(--header-snow,var(--snow)));
    font-family: "Overpass Mono", monospace;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-self: center;
  margin: 0 0 0 auto;
`;

export const Tooltip = styled(AccessibleTooltip)`
  border: 1px solid transparent;
  border-radius: 2px;
  &:focus {
    outline: none;
    border-color:var(--section-granite-border-color,var(--section-granite,var(--granite)));
  }
`;

export const Button = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: none;
  text-align: center;
  background: ${({ active }) =>
    active ?"var(--button-granite-background-color,var(--button-granite,var(--granite)))": "none"};

  svg {
    max-width: 18px;
    max-height: 20px;
    g path {
      fill: ${({ active }) =>
        active
          ?"var(--icon-snow-fill,var(--icon-snow,var(--snow)))":"var(--icon-granite-fill,var(--icon-granite,var(--granite)))"};
    }
  }

  &:hover {
    background: ${({ active }) =>
      active
        ?"var(--button-granite-background-color,var(--button-granite,var(--granite)))":"var(--button-slate-background-color,var(--button-slate,var(--slate)))"};
    svg g path {
      fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
    }
  }
`;
