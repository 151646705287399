import styled from "styled-components";
import { css, SECTION } from "Libs/themes";

export const Wrapper = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));

  @media (min-width: 768px) {
    display: inline-block;
    /* The 42px is to leave space for the commits count */
    max-width: calc(100% - 42px);
  }

  &:focus {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

export const Text = styled.span``;
