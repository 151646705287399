import styled from "styled-components";
import { css, getCSSVarString, TOOLTIP } from "Libs/themes";

export const Wrapper = styled.div`
  position: absolute;
  background-color:var(--tooltip-ebony-background-color,var(--tooltip-ebony,var(--ebony)));
  color:var(--tooltip-snow-color,var(--tooltip-snow,var(--snow)));
  padding: 6px 16px 7px 16px;
  height: auto !important;
  width: auto !important;
  font-size: 13px;
  overflow: visible !important;
  box-shadow: ${props =>
    props.theme.name === "dark"
      ? `var(--tooltip-box-shadow,var(--box-shadow))
      var(--tooltip-ebony-shadow-box-shadow-color,var(--tooltip-ebony-shadow,var(--ebony-shadow)))`
      : "0px 6px 24px rgba(152, 160, 171, 0.4)"};
  z-index: 8;
  margin-top: 4px;
  border-radius: 2px;

  transform: ${props => props.transform};

  &:after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: var(--tooltip-ebony-border-color,var(--tooltip-ebony,var(--ebony)));
    top: -14px;
    left: 13px;
    margin-left: -5px;
    height: 0;
    width: 0;
  }
`;
