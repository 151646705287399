import { useCallback, useState } from "react";

const useInput = (initialValues = {}, callback) => {
  const [inputs, setInputs] = useState(initialValues);

  const handleInputChange = useCallback(evt => {
    const { target } = evt;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setInputs(inputs => ({ ...inputs, [target.name]: value }));
    if (callback) callback(evt);
  });

  return {
    inputs,
    handleInputChange,
    initialValues,
    setInputs
  };
};

export default useInput;
