import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Heading6 from "Components/styleguide/Heading6";
import Label from "Components/fields/Label";
import Checkbox from "ds/Checkbox";
import Loading from "Components/Loading";
import InvitationConfirmationModal from "../AccessForm/InvitationConfirmationModal";
import Button from "ds/Button";
import Sticker from "ds/Stickers";

import * as S from "./styles";

import { statusSelector } from "Reducers/invitation";

const InvitationDetails = ({ invitation, onRevoke, onResendInvitation }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const intl = useIntl();

  const status = useSelector(statusSelector);

  useEffect(() => {
    if ((status === "resent") & modalIsOpen) setModalIsOpen(false);
  }, [status]);
  const { environments = [] } = invitation;

  return (
    <S.Layout>
      <Sticker
        priority="information"
        variant="button"
        text={intl.formatMessage(
          { id: "invitation.info" },
          {
            name: invitation?.owner?.display_name,
            date: momentjs(invitation.createdAt).format("MMMM D, YYYY")
          }
        )}
        button={
          status === "resent" ? (
            <span className="link-style">
              {intl.formatMessage({ id: "invitation.resend_success" })}
            </span>
          ) : (
            <S.ResendButton className="link-style" onClick={onResendInvitation}>
              {intl.formatMessage({ id: "invitation.resend" })}
            </S.ResendButton>
          )
        }
      >
        <InvitationConfirmationModal
          isOpen={modalIsOpen}
          email={invitation.email}
          closeModal={() => setModalIsOpen(false)}
        />
        {status === "pending" && <Loading />}
      </Sticker>

      <S.InputLayout className="field">
        <Checkbox
          label={`${intl.formatMessage({ id: "project_admin" })}`}
          checked={invitation.role === "admin"}
          disabled={true}
          aria-disabled
          forId="super-user"
        />
      </S.InputLayout>

      <S.EnvironmentPermissions className="environment-permissions">
        {!!environments.length && (
          <>
            <Heading6>
              {intl.formatMessage({ id: "invitation.environment.permission" })}
            </Heading6>
            <S.EnvironmentHeaders className="environment-headers">
              <Label>
                {intl.formatMessage({ id: "invitation.environment" })}
              </Label>
              <Label>
                {intl.formatMessage({ id: "invitation.environment.type" })}
              </Label>
            </S.EnvironmentHeaders>
            <S.TableLine />

            {environments.map(env => (
              <S.PermissionWrapper key={env.id}>
                <Label>{env.title}</Label>
                <Label>{env.role}</Label>
              </S.PermissionWrapper>
            ))}
          </>
        )}
      </S.EnvironmentPermissions>

      <Button
        id={`invitation-revoke-${invitation.id}`}
        variant="outline"
        onClick={onRevoke}
      >
        {intl.formatMessage({ id: "invitation.revoke" })}
      </Button>
    </S.Layout>
  );
};

InvitationDetails.propTypes = {
  invitation: PropTypes.object,
  onRevoke: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onCancel: PropTypes.func
};

export default InvitationDetails;
