import React from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import ModalConfirmDelete from "Components/ModalConfirmDelete";

const Message = styled.p`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 15px;
  margin: 0 0 8px;
  line-height: 24px;
  &:nth-child(2) {
    margin-bottom: 32px;
  }
`;

const Note = styled.p`
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 13px;
  ${semiBoldAlias};
`;

const ModalBody = ({ activity }) => {
  momentjs.updateLocale("en", {
    relativeTime: {
      past: "%s ago"
    }
  });

  return (
    <>
      <Message>
        Are you sure you want to restore the backup{" "}
        <strong>{activity.payload.backup_name}</strong> created{" "}
        {momentjs(activity.created_at).fromNow()}?
      </Message>
      <Message>
        This will delete and then overwrite all data from all running services
        and files of the {activity.payload.environment.title} environment.
      </Message>
      <Note>Note: this action can not be undone.</Note>
    </>
  );
};

ModalBody.propTypes = {
  activity: PropTypes.object
};

const BackupRestoreModal = ({ activity, show, setShow }) => {
  return (
    <ModalConfirmDelete
      isOpen={show}
      closeModal={() => setShow(false)}
      title="Restore from backup"
      deleteFunction={() => {
        activity.restore();
        setShow(false);
      }}
      modalSize="medium"
      body={<ModalBody activity={activity} />}
      deleteText="Yes, restore"
    />
  );
};

BackupRestoreModal.propTypes = {
  activity: PropTypes.object,
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default BackupRestoreModal;
