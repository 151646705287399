import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import LabeledInfo from "Components/LabeledInfo";

const getBooleanValue = (object, intl) => {
  if (!object || !object.enabled) {
    return intl.formatMessage({ id: "disabled" });
  }

  return intl.formatMessage({ id: "enabled" });
};

const RouteInfos = ({ metadata }) => {
  const intl = useIntl();

  return (
    <>
      <LabeledInfo
        gridColumn="auto"
        label={intl.formatMessage({ id: "type" })}
        value={metadata.type}
      />
      <LabeledInfo
        gridColumn="auto"
        label={intl.formatMessage({ id: "cache" })}
        value={getBooleanValue(metadata.cache, intl)}
      />
      <LabeledInfo
        gridColumn="auto"
        infoDialog
        infoDialogTitle="Server Side Includes (ssi)"
        infoDialogText="Let’s you serve dynamic content and leverage caching"
        infoDialogLinkText="Learn more"
        infoDialogLinkTo={intl.formatMessage({
          id: "links.documentation.routes_SSI"
        })}
        label={intl.formatMessage({ id: "ssi" })}
        value={getBooleanValue(metadata.ssi, intl)}
      />
    </>
  );
};

RouteInfos.propTypes = {
  metadata: PropTypes.object
};

export default RouteInfos;
