import React, { useState } from "react";
import PropTypes from "prop-types";

import { CloseButton } from "ds/Button";
import ErrorIcon from "Icons/ErrorIcon";

import { Layout, TextContainer } from "./styles";

const LargeInlineError = ({ children, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    isOpen && (
      <Layout>
        <ErrorIcon />
        <TextContainer>{children}</TextContainer>
        <CloseButton
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
        />
      </Layout>
    )
  );
};

LargeInlineError.defaultProps = {
  onClose: () => undefined
};

LargeInlineError.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
};

export default LargeInlineError;
