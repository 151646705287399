import styled, { css as styledCSS } from "styled-components";

import { ICON, css, getCSSVarString } from "Libs/themes";

export const TreeLayout = styled.div`
  overflow: visible;
  > div {
    overflow: visible;

    > svg {
      overflow: visible;
    }
  }

  svg,
  .node,
  rect:focus {
    outline: none;
  }

  .node rect {
    cursor: pointer;
    fill:var(--icon-snow-fill,var(--icon-snow,var(--snow)));
  }

  .icon {
    cursor: pointer;
  }

  .edgePath {
    stroke: ${props =>
      props.strokeColor ||"var(--icon-grey-stroke,var(--icon-grey,var(--grey)))"};
    fill: none;
    stroke-width: 2.5px;
    stroke-linecap: round;
  }
  .edgePath.highlight {
    stroke:var(--icon-ebony-light-stroke,var(--icon-ebony-light,var(--ebony-light)));
  }

  .node:hover .tooltip {
    opacity: 1;
  }

  ${props =>
    props.theme.name === "contrast" &&
    styledCSS`
      .badge {
        circle {
          fill: #5f5e70 !important;
        }
        path {
          fill: #fff !important;
        }
      }
    `};
`;
