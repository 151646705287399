import React from "react";
import PropTypes from "prop-types";

import { getCSSVarString, ICON } from "Libs/themes";

const Email = ({ size = "32px" }) => {
  const color ="var(--icon-ebony-light-stroke,var(--icon-ebony-light,var(--ebony-light)))";

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
    >
      <rect
        x="5"
        y="8"
        width="22"
        height="16.6667"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M4.6665 11.667L14.4768 15.7065C15.4524 16.1083 16.5472 16.1083 17.5228 15.7065L27.3332 11.667"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

Email.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Email;
