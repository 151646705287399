import React from "react";
import styled, { withTheme, css as styledCSS } from "styled-components";
import PropTypes from "prop-types";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, BUTTON, getCSSVarString, ICON } from "Libs/themes";

import getIcon from "Icons/technologies";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const getThemeAttributes = (theme, selected) => {
  if (theme.name === "dark") {
    return selected
      ? `color:var(--button-skye-color,var(--button-skye,var(--skye))); box-shadow: 0px 8px 24px #1A192B;`
      : "color: #98A0AB";
  }

  return selected
    ? `color: ${css(
        BUTTON,
        "night",
        "color"
      )}; box-shadow: 0px 6px 24px rgba(152, 160, 171, 0.4);`
    : "color: rgba(0, 0, 0, 0.7);";
};

const Row = styled.button.attrs({ type: "button", tabIndex: 0 })`
  border: none;
  font-family: inherit;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  padding: 16px;
  ${props => getThemeAttributes(props.theme, props.selected)};

  &:hover {
    background-color:var(--button-snow-background-color,var(--button-snow,var(--snow)));
    box-shadow: 0 4px 8px
      ${props =>
        props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};
  }

  ${({ selected }) =>
    selected &&
    styledCSS`
      ${Name} {
        color:var(--button-skye-color,var(--button-skye,var(--skye)));
      }
      background-color:var(--button-snow-background-color,var(--button-snow,var(--snow)));
      box-shadow: 0 4px 8px
        ${props =>
          props.theme.name == "dark" ? "#1A192B" : "rgba(152, 160, 171, 0.4)"};
    `};
`;

const RowLeftLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img + span {
    margin-left: 16px;
  }
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  opacity: ${props => props.opacity};
`;

const Name = styled.span`
  text-transform: capitalize;
  ${semiBoldAlias};
  margin-left: 16px;
`;

const addClass = (list = {}, newClass) => {
  return Object.keys(list).reduce((current, key) => {
    current[key] = { class: newClass, ...list[key] };
    return current;
  }, {});
};

const ServicesList = ({ onClick, currentDeployment, search, selected }) => {
  const [activeTab, setActivetab] = React.useState(selected);
  const nodes = {
    routes: { class: "router", ...(currentDeployment.routes || {}) },
    ...addClass(currentDeployment.webapps, "app"),
    ...addClass(currentDeployment.services, "service")
  };

  return (
    <Layout>
      {Object.keys(nodes)
        .filter(k =>
          search && search.length
            ? k.toUpperCase().startsWith(search.toUpperCase())
            : true
        )
        .map(k => {
          const node = nodes[k];
          node.name = k;
          const icon = !node.type ? "router" : node.type.split(":")[0];

          return (
            <Row
              key={k}
              selected={activeTab === k}
              onClick={() => {
                onClick({
                  ...node,
                  icon,
                  class: node.class,
                  metadata: {
                    appName: k
                  }
                });
                setActivetab(k);
              }}
            >
              <RowLeftLayout>
                <Logo
                  opacity={activeTab === k ? "1" : "0.7"}
                  src={`data:image/svg+xml;base64,${window.btoa(
                    getIcon(
                      icon,
                      0,
                      0,
                      24,
                      24,"var(--icon-slate-fill,var(--icon-slate,var(--slate)))")
                  )}`}
                />
                <Name>{node.name}</Name>
              </RowLeftLayout>
            </Row>
          );
        })}
    </Layout>
  );
};

ServicesList.propTypes = {
  onClick: PropTypes.func,
  currentDeployment: PropTypes.object,
  theme: PropTypes.object,
  search: PropTypes.string,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  selected: PropTypes.string,
  environmentId: PropTypes.string
};

export default withTheme(ServicesList);
