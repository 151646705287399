import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, LINK, ICON, MODAL } from "Libs/themes";

import UnstyledAvatar from "Components/Avatar";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px;
  color:var(--modal-ebony-light-color,var(--modal-ebony-light,var(--ebony-light)));
  background-color:var(--modal-snow-background-color,var(--modal-snow,var(--snow)));
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }

  & * {
    box-sizing: border-box;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    overflow: hidden;
  }
`;

export const DateTime = styled.div`
  @media (min-width: 768px) {
    white-space: nowrap;
  }
`;

export const Icon = styled.div`
  display: none;
  margin-right: 16px;
  line-height: 0;
  color:var(--icon-slate-color,var(--icon-slate,var(--slate)));
  min-width: 32px;
  height: auto;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
  }

  svg {
    .fill-target {
      fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
    }
    .stroke-target {
      stroke:var(--icon-slate-stroke,var(--icon-slate,var(--slate)));
    }
  }
`;

export const Avatar = styled(UnstyledAvatar)`
  display: none;
  margin-right: 24px;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

// This div comes as HTML from the API
// That's why we use nested styles here
export const Event = styled.div`
  width: 100%;
  flex-grow: 1;
  line-height: 14px;
  overflow: hidden;

  strong a {
    font-weight: 700;
    text-decoration: none;
    color: inherit;
  }

  strong a:hover,
  strong a:focus {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
  }
`;

export const Title = styled.div`
  @media (max-width: 769px) {
    > span {
      white-space: normal;
    }
  }
`;

export const DetailsWrapper = styled.div`
  font-size: 12px;
  color:var(--modal-granite-color,var(--modal-granite,var(--granite)));
  display: flex;
  line-height: 18px;
  margin-top: 2px;

  @media (min-width: 768px) {
    display: block;
    width: 100%;
    white-space: nowrap;
  }
`;

export const Sha = styled.span``;

export const Message = styled.span`
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Date = styled.span`
  ${semiBoldAlias};
  min-width: 125px;
  margin-left: 16px;
  font-size: 12px;
  text-align: left;
  color:var(--modal-granite-color,var(--modal-granite,var(--granite)));

  @media (min-width: 768px) {
    font-size: 13px;
    margin-right: 16px;
  }
`;

export const Duration = styled.span`
  ${semiBoldAlias};
  min-width: 56px;
  font-size: 12px;
  text-align: left;
  color:var(--modal-granite-color,var(--modal-granite,var(--granite)));

  @media (min-width: 768px) {
    font-size: 13px;
    margin-right: 16px;
  }
`;
