import styled, { css as styledCSS } from "styled-components";

import { breakpoints, semiBoldAlias } from "Libs/theme";
import { css, MODAL } from "Libs/themes";

export const Header = styled.header`
  margin-bottom: 24px;
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

export const Org = styled.h4`
  margin: 8px 0 0 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #98a0ab;
`;

export const DotDotDot = styled.div`
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 20px;
  margin: 16px 0 0 12px;

  &:after {
    display: inline-block;
    content: "";
    animation: loader 2s infinite linear;
    width: 10px;
    margin-left: 2px;
    text-align: left;
  }

  @keyframes loader {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
  }
`;

export const Empty = styled.div`
  margin: 16px 0 0 12px;
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 20px;
`;

export const LogPre = styled.pre`
  position: relative;
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color:var(--modal-ebony-background-color,var(--modal-ebony,var(--ebony)));
  font-family: "Overpass Mono", monospace;
  code {
    color:var(--modal-snow-color,var(--modal-snow,var(--snow)));
  }

  @media (max-width: ${breakpoints[1]}) {
    margin: 0 -16px;
  }

  ${props =>
    props.showLinesNumber &&
    styledCSS`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 42px;
        background-color:var(--modal-ebony-light-background-color,var(--modal-ebony-light,var(--ebony-light)));
      }
    `};
`;

export const Container = styled.div`
  flex: 1;
  > div {
    padding: 0;
    box-sizing: border-box;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const LineNumber = styled.div`
  display: inline-block;
  width: 34px;
  height: 100%;
  vertical-align: top;
  padding: 0 4px;
  cursor: pointer;
  background-color:var(--modal-ebony-light-background-color,var(--modal-ebony-light,var(--ebony-light)));
  color:var(--modal-periwinkle-grey-color,var(--modal-periwinkle-grey,var(--periwinkle-grey)));
  line-height: 21px;
  text-align: right;
  user-select: none;

  &:hover {
    color:var(--modal-snow-color,var(--modal-snow,var(--snow)));
  }
`;

export const TimeStamp = styled.div`
  display: none;
  position: absolute;
  right: 16px;
  top: 5px;
  color:var(--modal-gold-color,var(--modal-gold,var(--gold)));
  ${semiBoldAlias};
  font-family: "Overpass Mono", monospace;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
`;

export const Line = styled.div`
  position: relative;

  &::selection,
  *::selection {
    background-color:var(--modal-light-blue-background-color,var(--modal-light-blue,var(--light-blue)));
  }
  code {
    padding: 0;
    margin: 0 0 0 16px;
    max-width: calc(100% - 150px);
    line-height: 21px;
    background: none;
    white-space: break-spaces;
  }

  &:hover {
    background-color:var(--modal-light-blue-hover-background-color,var(--modal-light-blue-hover,var(--light-blue-hover)));
    ${LineNumber} {
      background-color:var(--modal-light-blue-hover-background-color,var(--modal-light-blue-hover,var(--light-blue-hover)));
      color:var(--modal-snow-color,var(--modal-snow,var(--snow)));
    }
    ${TimeStamp} {
      display: block;
    }
  }

  ${props =>
    props.selected &&
    styledCSS`
      code {
        background: none;
      }
      &,
      ${LineNumber} {
        background-color:var(--modal-light-blue-background-color,var(--modal-light-blue,var(--light-blue)));
        color:var(--modal-snow-color,var(--modal-snow,var(--snow)));
      }
    `};

  ${props =>
    props.showTimeStamp &&
    styledCSS`
      ${TimeStamp} {
        display: block;
      }
    `};
`;

export const Spacer = styled.div`
  height: 16px !important;
`;

export const ActivitiyActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:16px 0 8px 0;
}
`;

export const LogTitle = styled.span`
  font-size: 13px;
  font-weight: 600;
  color:var(--modal-slate-color,var(--modal-slate,var(--slate)));
`;

export const BuildLogHeader = styled.div`
  svg {
    min-height: 24px;
    align-self: start;
  }
  @media (min-width: 768px) {
    .activity-header-wrapper {
      margin-bottom: 0;
    }
  }
`;

export const ScrollButtons = styled.div`
  margin: 0 0 0 32px;
`;
