import styled from "styled-components";
import { getCSSVarString, SECTION, css, TOOLTIP } from "Libs/themes";

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid var(--section-grey-border-color,var(--section-grey,var(--grey)));
  overflow: hidden;
  &.no-screenshot .with-image {
    background: #5f5e70;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  svg {
    width: 40%;
    height: auto;
    margin: 20px auto;
  }
  &.without-image {
  }
`;

export const NoImage = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color:var(--project-preview-section-background-color,var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey))));
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  &:hover::after {
    content: ${props => `"${props.previewMessage}"`};
    color:var(--tooltip-snow-color,var(--tooltip-snow,var(--snow)));
    position: absolute;
    bottom: 10px;
    text-align: center;
    box-sizing: border-box;
    height: 32px;
    border-radius: 3px;
    padding: 6px 16px 8px 16px;
    background-color:var(--tooltip-ebony-background-color,var(--tooltip-ebony,var(--ebony)));
  }
  &:hover::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 42px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid
      var(--tooltip-ebony-border-color,var(--tooltip-ebony,var(--ebony)));
  }
`;
