import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import { CardElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import { css, INPUT, getCSSVarString, ICON } from "Libs/themes";

import client from "Libs/platform";
import logger from "Libs/logger";
import Loading from "Components/Loading";
import Button from "ds/Button";
import Label from "Components/fields/Label";
import InputField from "Components/fields/InputField";
import RequiredTag from "Components/fields/RequiredTag";
import Error from "Components/Error";

const Layout = styled.div`
  max-width: 100%;
  .StripeElement {
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    color:var(--input-night-color,var(--input-night,var(--night)));
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 32px;
  }
  .setting-line {
    background-color:var(--input-ice-background-color,var(--input-ice,var(--ice)));
    border: 1px solid var(--input-dark-shade-border-color,var(--input-dark-shade,var(--dark-shade)));
    color:var(--input-night-color,var(--input-night,var(--night)));
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
`;

const CardSection = props => {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [secret, setSecret] = useState();

  const { intl, number, edit, onEdit, onCancel, hideButtons } = props;

  useEffect(async () => {
    setIsLoading(true);
    let error, secret;

    try {
      secret = await client.createPaymentSourceIntent();
    } catch (err) {
      error = err;
    }

    setIsLoading(false);
    setSecret(secret.client_secret);
    setError(error);
  }, []);

  const handleSubmit = async ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (props.edit) {
      setIsLoading(true);

      try {
        const response = await props.stripe.handleCardSetup(secret, {
          payment_method_data: {
            billing_details: { name: props.name, email: props.email }
          }
        });

        if (response.error) {
          throw response.error;
        }

        const cardTokenId = response.setupIntent?.payment_method;

        const creditcard = await client.addPaymentSource(
          "credit-card",
          cardTokenId
        );

        setIsLoading(false);
        setError(null);

        props.onSuccess && props.onSuccess(creditcard);
      } catch (err) {
        logger(err);
        setIsLoading(false);
        setError(err);
      }
    } else {
      props.onSuccess && props.onSuccess();
    }
  };

  return (
    <Layout>
      <form aria-labelledby="edit-card" id="stripe-payment-form">
        {error && (
          <Error>
            {error.message ||
              error.description ||
              JSON.stringify(error, null, 4)}
          </Error>
        )}
        {isLoading && <Loading />}
        {!edit && number ? (
          <React.Fragment>
            <LiveMessage
              message="click update credit card to edit"
              aria-live="polite"
            />
            <InputField
              label="credit card"
              className="card-number"
              isDisabled={true}
              value={number.replace(/X/g, "•").replace(/-/g, " ")}
            />
            <div className="">
              <Button
                onClick={onEdit}
                type="button"
                aria-label={intl.formatMessage({ id: "update_credit_card" })}
              >
                {intl.formatMessage({ id: "update_credit_card" })}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div className="new-card">
            <LiveMessage
              message="enter credit card information"
              aria-live="polite"
            />
            <Label>
              {intl.formatMessage({ id: "credit_card_number" })} <RequiredTag />
            </Label>
            <CardElement
              className="stripe-card"
              style={{
                base: {
                  fontSize: "14px",
                  iconColor:"var(--icon-night-color,var(--icon-night,var(--night)))",
                  color:"var(--input-night-color,var(--input-night,var(--night)))",
                  backgroundColor: getCSSVarString(
                    INPUT,
                    "ice",
                    "background-color"
                  ),
                  "::placeholder": {
                    fontSize: "14px",
                    color:"var(--input-night-color,var(--input-night,var(--night)))"
                  }
                }
              }}
              iconStyle="solid"
            />
            {!hideButtons && (
              <React.Fragment>
                <Button id="stripe_cardsection_submit" onClick={handleSubmit}>
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                {number && (
                  <Button
                    id="stripe_cardsection_cancel"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    cancel
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </form>
    </Layout>
  );
};

CardSection.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  number: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  theme: PropTypes.object,
  onCancel: PropTypes.func
};

export default injectStripe(injectIntl(withTheme(CardSection)));
