import styled from "styled-components";

import { getCSSVarString, TAB, css, SECTION } from "Libs/themes";

export const Tab = styled.span`
  color: ${({ isActive }) =>
    isActive
      ?"var(--tab-skye-color,var(--tab-skye,var(--skye)))":"var(--tab-ebony-light-color,var(--tab-ebony-light,var(--ebony-light)))"};
  background: none;

  padding: 0 0 2px 0;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border: none;
  display: block;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
`;

export const TabList = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  gap: 16px;
`;

export const TabPanel = styled.div``;

export const TabContainer = styled.div`
  border-radius: 2px;
  width: 100%;
`;

export const TabUnderLine = styled.div`
  height: 2px;
  background: ${props =>
    props.isActive
      ?"var(--tab-skye-background-color,var(--tab-skye,var(--skye)))": "transparent"};
  width: 100%;
  position: relative;
  top: 7px;
  transition: background 0.2s ease-in-out;
`;

export const TabDivider = styled.hr`
  background-color:var(--section-stone-background-color,var(--section-stone,var(--stone)));
  margin-top: 5px;
`;
