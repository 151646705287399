import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Sticker from "ds/Stickers";

const ErrorMessageWrapper = styled(Sticker)`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  margin: 32px 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  pre {
    line-height: 21px;
    font-size: 12px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    white-space: pre;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 4px;
    text-align: left;
  }
`;

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    const spinner = document.getElementById("pre-loading");
    if (spinner) {
      spinner.remove();
    }
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorMessageWrapper className="error-wrapper" priority="critical">
          {error.message}
        </ErrorMessageWrapper>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
