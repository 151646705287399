import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";

import ListField from "Components/fields/ListField";

const fields = ["login", "password"];
class LoginListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.editLine = this.editLine.bind(this);
    this.removeLine = this.removeLine.bind(this);
    this.state = {
      logins: [...props.basicAuth],
      removed: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ logins: [...nextProps.basicAuth] });
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.logins[index] = {
        ...this.state.logins[index],
        [field]: value
      };

      this.props.onChange(nextState.logins, nextState.removed);
      return nextState;
    });
  }

  onAdd() {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.logins.push({});
      nextState.editedLine = nextState.logins.length - 1;
      return nextState;
    });
  }

  editLine(index) {
    this.setState(state => {
      // We set the previous value to removed, if the user change the login the previous will be removed
      state.removed[state.logins[index]?.login] = true;
      state.editedLine = index;

      return state;
    });
  }

  removeLine(index) {
    this.setState(prevState => {
      prevState.removed[prevState.logins[index]?.login] = true;
      prevState.logins.splice(index, 1);

      this.props.onChange(prevState.logins, prevState.removed);

      return prevState;
    });
  }

  render() {
    const { intl, enabled, errors = {} } = this.props;

    const fieldErrors = {};
    let generalError = "";

    Object.keys(errors).forEach(errField => {
      const res = errField.match(/^http_access\.basic_auth\.(.*)/);

      if (res) {
        if (res[1] === "key") {
          generalError = errors[errField];
        } else {
          const log = this.state.logins.find(log => log.login === res[1]);
          const line = this.state.logins.indexOf(log);
          generalError = "";

          fieldErrors[line] = { password: errors[errField] };
        }
      }
    });

    return (
      <ListField
        id="login-list-field"
        errors={fieldErrors}
        generalError={generalError}
        onChange={this.onChange}
        onAdd={this.onAdd}
        values={this.state.logins}
        addButtonText={intl.formatMessage({ id: "add_login" })}
        fields={fields}
        enabled={enabled}
        editedLine={this.state.editedLine}
        editLine={this.editLine}
        removeLine={this.removeLine}
        className="login-list-field"
      />
    );
  }
}

const mapStateToProps = (
  state,
  { organizationId, projectId, environmentId }
) => ({
  httpAccess: environmentSelector(state, {
    organizationId,
    projectId,
    environmentId
  })?.http_access,
  isLoading: environmentLoadingSelector(state, { projectId, environmentId })
});

LoginListField.propTypes = {
  onChange: PropTypes.func,
  basicAuth: PropTypes.array,
  isSmtpEnabled: PropTypes.bool,
  enabled: PropTypes.bool,
  intl: PropTypes.object,
  errors: PropTypes.object
};

export default connect(mapStateToProps)(injectIntl(LoginListField));
