import styled from "styled-components";

import { getCSSVarString, SECTION } from "Libs/themes";

const TableCell = styled.div`
  width: 100%;
  color: ${props =>
    props.color ||"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))"};
  min-width: ${props => (props.width ? props.width + "px" : "auto")};
`;

export default TableCell;
