import React from "react";
import PropTypes from "prop-types";

import { getCSSVarString, ICON } from "Libs/themes";

const Webhook = ({ size = "32px" }) => {
  const color ="var(--icon-ebony-light-stroke,var(--icon-ebony-light,var(--ebony-light)))";

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
    >
      <path
        d="M14.4891 19.968C14.759 20.9877 14.7205 22.0646 14.3784 23.0624C14.0363 24.0602 13.406 24.9342 12.5672 25.5738C11.7284 26.2134 10.7188 26.5899 9.66604 26.6557C8.61326 26.7215 7.56461 26.4736 6.6527 25.9435C5.74078 25.4133 5.00657 24.6246 4.54289 23.6771C4.07922 22.7297 3.90691 21.666 4.04776 20.6206C4.18861 19.5752 4.63629 18.5951 5.33419 17.8041C6.03209 17.0132 6.94886 16.4469 7.96858 16.177"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14.6807 14.4356C13.6626 14.1595 12.7493 13.5878 12.0562 12.7926C11.3631 11.9974 10.9213 11.0146 10.7868 9.96836C10.6523 8.92214 10.8311 7.85953 11.3005 6.91489C11.7699 5.97026 12.5089 5.18603 13.424 4.66138C14.3391 4.13673 15.3892 3.89523 16.4416 3.96741C17.4939 4.03958 18.5012 4.4222 19.3362 5.06687C20.1711 5.71155 20.796 6.58932 21.1321 7.5892C21.4681 8.58907 21.5001 9.66614 21.224 10.6842"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.8302 17.6835C19.5784 16.9399 20.5302 16.4348 21.5654 16.2322C22.6006 16.0295 23.6726 16.1384 24.6459 16.545C25.6192 16.9516 26.4501 17.6377 27.0335 18.5166C27.6169 19.3954 27.9265 20.4275 27.9233 21.4823C27.9202 22.5371 27.6042 23.5673 27.0155 24.4426C26.4269 25.3179 25.5918 25.999 24.6161 26.3997C23.6403 26.8004 22.5677 26.9028 21.5337 26.6938C20.4998 26.4849 19.551 25.9741 18.8074 25.226"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="9.33335"
        cy="21.3334"
        r="1.66667"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="22.6667"
        cy="21.3333"
        r="1.66667"
        transform="rotate(-90 22.6667 21.3333)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="16"
        cy="9.33333"
        r="1.66667"
        transform="rotate(-180 16 9.33333)"
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1="10.2889"
        y1="19.9883"
        x2="13.6223"
        y2="14.2148"
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1="21.3333"
        y1="21"
        x2="14.6666"
        y2="21"
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1="16.0203"
        y1="10.2969"
        x2="19.3537"
        y2="16.0704"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

Webhook.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Webhook;
